import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";

const DetailSection = () => {
    const { t, i18n } = useTranslation();
    return (
        <DetailWrapper>
            <DetailHeader>{t("product-details")}</DetailHeader>
            <DetailContent>
                <Section>
                    <SectionTitle>
                        {i18n.language === "vi"
                            ? "Giới thiệu ván ép chà dán"
                            : "Introduction to Commercial Plywood"}
                    </SectionTitle>
                    <p className="md:pl-4 text-gray-600 leading-relaxed">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {i18n.language === "vi" ? (
                            <>
                                <strong>Ván ép chà dán</strong> là loại ván ép cao cấp, sản phẩm này
                                được sử dụng rộng rãi trong ngành xây dựng và sản xuất đồ nội thất.
                                Nó được tạo ra bằng cách ép các lớp gỗ mỏng với keo chất lượng, sau
                                đó bề mặt được chà nhám và phủ một lớp keo để nâng cao độ bền cũng
                                như tính thẩm mỹ. Ván ép chà dán của chúng tôi đảm bảo độ mịn màng
                                và phẳng, sẵn sàng cho quy trình sơn phủ hoặc hoàn thiện khác.
                            </>
                        ) : (
                            <>
                                <strong>Commercial plywood</strong> is a premium plywood, this
                                product is widely used in the construction and furniture
                                manufacturing industries. It is made by pressing thin layers of wood
                                with high-qualified glue, then the surface is sanded and coated with
                                a layer of glue to enhance durability and aesthetics. Our sanded
                                plywood ensures a smooth, flat surface ready for painting or other
                                finishing processes.
                            </>
                        )}
                    </p>
                </Section>

                <Section>
                    <SectionTitle>{t("product-information")}</SectionTitle>
                    <TableWrapper>
                        <Table>
                            <tbody>
                                <Tr>
                                    <TdLabel>{t("measurement")}</TdLabel>
                                    <TdContent>1220x2440mm, 910x1820mm,….</TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("popular-thickness")}</TdLabel>
                                    <TdContent>
                                        <p>
                                            8.5mm - 4 {t("layer")} 1.7 mm + 1 {t("layer")} 2 mm
                                        </p>
                                        <p>11.5mm - 7 {t("layer")} 1.7mm</p>
                                        <p>14.5mm - 9 {t("layer")} 1.7mm</p>
                                        <p>17.5mm - 11 {t("layer")} 1.7mm</p>
                                    </TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("tolerance")}</TdLabel>
                                    <TdContent>±0.3mm</TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("veneer-grade")}</TdLabel>
                                    <TdContent>
                                        {i18n.language === "vi"
                                            ? "Độn A/B/C tùy theo mục đích sử dụng"
                                            : "Grade A/B/C, depends on purposes"}
                                    </TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("application")}</TdLabel>
                                    <TdContent>
                                        {i18n.language === "vi"
                                            ? "Bao bì chất lượng cao, pallet, robbin, nội thất, xây dựng..."
                                            : "High-qualified packaging, pallets, robbin, furniture, construction..."}
                                    </TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("glue")}</TdLabel>
                                    <TdContent>MR urea formaldyhe</TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("formaldehyde-emission")}</TdLabel>
                                    <TdContent>E0/E1/E2</TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("face")}</TdLabel>
                                    <TdContent>Okume 0.25mm, Bintangor 0.13-0.15mm</TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("color")}</TdLabel>
                                    <TdContent>{i18n.language === "vi" ? "Đỏ" : "Red"}</TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("species")}</TdLabel>
                                    <TdContent>
                                        {i18n.language === "vi"
                                            ? "Keo, bồ đề, bạch đàn"
                                            : "Acacia, Styrax, Eucalyptus"}
                                    </TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("technology-information")}</TdLabel>
                                    <TdContent>
                                        <p>
                                            {i18n.language === "vi"
                                                ? "1 lần ép nguội"
                                                : "1 cold pressing time"}
                                        </p>
                                        <p>
                                            {i18n.language === "vi"
                                                ? "2 lần ép nóng"
                                                : "2 hot pressing times"}
                                        </p>
                                        <p>{i18n.language === "vi" ? "Chà độn" : "Sand veneer"}</p>
                                        <p>
                                            {i18n.language === "vi"
                                                ? "Máy ép nóng 6-8 pitton"
                                                : "6-8 pistons hot pressing machine"}
                                        </p>
                                    </TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("density")}</TdLabel>
                                    <TdContent>
                                        550 - 580kgs/m<sup>3</sup>
                                    </TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("moisture")}</TdLabel>
                                    <TdContent>
                                        {i18n.language === "vi" ? "Dưới 12%" : "Under 12%"}
                                    </TdContent>
                                </Tr>
                            </tbody>
                        </Table>
                    </TableWrapper>
                </Section>

                <Section>
                    <SectionTitle>
                        {i18n.language === "vi"
                            ? "Đặc điểm và tính năng của ván ép chà dán"
                            : "Characteristics and Features of Commercial Plywood"}
                    </SectionTitle>
                    <SectionContent>
                        <ContentTitle>
                            {i18n.language === "vi" ? "Chất liệu" : "Material"}
                        </ContentTitle>
                        <p className="content-text">
                            {i18n.language === "vi"
                                ? "Ván ép chà dán được sản xuất từ các loại gỗ tự nhiên như bạch đàn, thông hoặc cao su, kết hợp với keo dán chuyên dụng, nhằm đảm bảo độ bền và tính ổn định cho sản phẩm."
                                : "Commercial plywood is produced from natural woods such as eucalyptus, pine, or rubber, combined with specialized glue, to ensure the durability and stability of the product."}
                        </p>

                        <ContentTitle>
                            {i18n.language === "vi"
                                ? "Độ bền và khả năng chịu lực:"
                                : "Durability and load-bearing capacity:"}
                        </ContentTitle>
                        <ul className="content-list">
                            <li>
                                {i18n.language === "vi"
                                    ? "Ván ép chà dán có khả năng chịu lực tốt, không bị cong vênh, hay nứt nẻ trong suốt quá trình sử dụng."
                                    : "Commercial plywood has good load-bearing capacity and does not wrap or crack during use."}
                            </li>
                            <li>
                                {i18n.language === "vi"
                                    ? "Sản phẩm rất thích hợp cho các công trình nội thất và xây dựng cần tính bền vững và thẩm mỹ cao."
                                    : "The product is very suitable for interior and construction projects that require high durability and aesthetics."}
                            </li>
                        </ul>

                        <ContentTitle>
                            {i18n.language === "vi"
                                ? "Độ mịn và khả năng hoàn thiện:"
                                : "Smoothness and completing capacity:"}
                        </ContentTitle>
                        <p className="content-text">
                            {i18n.language === "vi"
                                ? "Bề mặt ván được chà nhám cẩn thận, không có vết xước hay gồ ghề, đảm bảo độ mịn hoàn hảo, sẵn sàng cho các quy trình sơn, dán veneer hoặc các lớp hoàn thiện khác."
                                : "The surface is carefully sanded, free of scratches or roughness, ensuring perfect smoothness, and ready for painting, veneering, or other finishing processes."}
                        </p>
                    </SectionContent>
                </Section>

                <Section>
                    <SectionTitle>
                        {i18n.language === "vi"
                            ? "Ứng dụng thực tế của ván ép chà dán"
                            : "Applications of Commercial Plywood"}
                    </SectionTitle>
                    <SectionContent>
                        <ContentTitle>
                            {i18n.language === "vi"
                                ? "Trong sản xuất nội thất:"
                                : "Furniture Manufacture:"}
                        </ContentTitle>
                        <ul className="content-list">
                            <li>
                                {i18n.language === "vi"
                                    ? "Ván ép chà dán được sử dụng phổ biến trong sản xuất đồ nội thất như bàn, ghế, tủ, giường và các sản phẩm trang trí khác."
                                    : "Commercial plywood is commonly used to produce furniture such as tables, chairs, cabinets, beds, and other decorative products."}
                            </li>
                            <li>
                                {i18n.language === "vi"
                                    ? "Với bề mặt mịn và độ ổn định cao, sản phẩm rất thích hợp cho việc dán veneer hoặc hoàn thiện bằng sơn."
                                    : "With a smooth surface and high stability, the product is very suitable for veneering or painting finishing."}
                            </li>
                        </ul>

                        <ContentTitle>
                            {i18n.language === "vi" ? "Trong xây dựng:" : "Construction:"}
                        </ContentTitle>
                        <p className="content-text">
                            {i18n.language === "vi"
                                ? "Sản phẩm này cũng được sử dụng trong các công trình xây dựng để làm vách ngăn, ốp tường, trần nhà, và các ứng dụng trang trí khác."
                                : "This product is also used in construction for partitions, wall cladding, ceilings, and other decorative applications."}
                        </p>

                        <ContentTitle>
                            {i18n.language === "vi" ? "Đóng gói:" : "Packing:"}
                        </ContentTitle>
                        <p className="content-text">
                            {i18n.language === "vi"
                                ? "Ván ép chà dán cũng được sử dụng trong sản xuất bao bì, thùng hàng và các cấu trúc nhẹ, nhờ vào tính linh hoạt và khả năng gia công tốt."
                                : "Due to its flexibility and good workability, commercial plywood is also used to produce packaging, containers, and light structures."}
                        </p>

                        <Conclusion>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {i18n.language === "vi" ? (
                                <>
                                    Với những ưu điểm vượt trội về chất lượng và đa dạng ứng dụng,{" "}
                                    <strong>ván ép chà dán</strong> là lựa chọn hàng đầu cho các
                                    doanh nghiệp trong lĩnh vực nội thất và xây dựng. Hãy chọn{" "}
                                    <strong>ván ép chà dán</strong> của{" "}
                                    <Link
                                        className="text-blue-600 underline font-semibold"
                                        to={"/"}
                                    >
                                        Lâm sản Phú Thọ
                                    </Link>{" "}
                                    để mang đến những sản phẩm hoàn hảo và đẳng cấp!
                                </>
                            ) : (
                                <>
                                    With outstanding advantages in quality and diverse applications,{" "}
                                    <strong>commercial plywood</strong> is the top choice for
                                    businesses in furniture and construction. Choose{" "}
                                    <strong>commercial plywood</strong> from{" "}
                                    <Link
                                        className="text-blue-600 underline font-semibold"
                                        to={"/"}
                                    >
                                        PhuTho Wood
                                    </Link>{" "}
                                    to bring perfect and premium products!
                                </>
                            )}
                        </Conclusion>
                    </SectionContent>
                </Section>
            </DetailContent>
        </DetailWrapper>
    );
};

export default DetailSection;

const DetailWrapper = styled.div`
    position: relative;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    padding: 24px;
    margin: 32px 0;
`;

const DetailHeader = styled.div`
    position: absolute;
    top: -20px;
    left: 16px;
    background-color: white;
    padding: 0 12px;
    font-size: 1.5rem;
    font-weight: 600;
    color: #4a5568;
`;

const DetailContent = styled.div`
    margin-top: 16px;
`;

const Section = styled.section`
    margin-bottom: 24px;
`;

const SectionTitle = styled.h3`
    color: #2f855a;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 16px;
`;

const TableWrapper = styled.div`
    // width: 100%;
    margin: 0 -16px;
    // overflow-x: auto;

    @media (min-width: 768px) {
        margin: 0 auto;
    }
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;

    @media (min-width: 768px) {
        min-width: 500px;
        max-width: 750px;
        margin-left: auto;
        margin-right: auto;
    }
`;

const Tr = styled.tr`
    &:hover {
        background-color: #f8f9fa;
    }
`;

const TdLabel = styled.td`
    font-weight: bold;
    width: 30%;
    min-width: 120px;
    word-wrap: break-word;
    white-space: normal;
    // padding: 8px;

    @media (min-width: 768px) {
        width: 25%;
        min-width: 150px;
    }
`;

const TdContent = styled.td`
    width: 65%;
    word-wrap: break-word;
    white-space: normal;
    padding: 8px;

    @media (min-width: 768px) {
        width: 75%;
    }
`;

const SectionContent = styled.div`
    .content-text {
        color: #4b5563; // equivalent to text-gray-600
        margin-left: 1rem; // equivalent to ml-4
        margin-bottom: 1rem; // equivalent to mb-4
        line-height: 1.625; // equivalent to leading-relaxed
    }

    .content-list {
        list-style-type: disc;
        list-style-position: outside;
        margin-left: 1rem;
        margin-bottom: 1rem;
        color: #4b5563;

        li {
            margin-bottom: 0.5rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
`;

const ContentTitle = styled.h4`
    font-weight: 600;
    color: #374151;
    margin-bottom: 5px;
    margin-left: 10px;
`;

const Conclusion = styled.p`
    position: relative;
    background: linear-gradient(to right, #f0f8ff, #e6f2ff);
    padding: 20px;
    border-radius: 8px;
    border-left: 4px solid #4299e1;
    font-size: 18px;
    font-style: italic;
    color: #2c5282;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    }

    &::before {
        content: "✓";
        position: absolute;
        top: 15px;
        left: -30px;
        background: #4299e1;
        color: white;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
    }
`;