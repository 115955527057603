import React, { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { FaFacebook, FaMapMarkerAlt, FaPhone, FaWhatsapp } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { SiZalo } from "react-icons/si";
import styled from "styled-components";

const Contact = () => {
    const { t, i18n } = useTranslation();

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        preferredDate: "",
        message: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER}/api/confirm-order`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    ...formData,
                    language: i18n.language,
                }),
            });

            if (!response.ok) {
                throw new Error("Failed to submit");
            }

            setFormData({
                name: "",
                email: "",
                phone: "",
                preferredDate: "",
                message: "",
            });

            toast.success(
                i18n.language === "vi"
                    ? "Đặt lịch tư vấn thành công! Chúng tôi sẽ liên hệ lại với bạn sớm."
                    : "Order submitted successfully! We will contact you soon."
            );
        } catch (error) {
            console.error("Error submitting form:", error);
            toast.error(
                i18n.language === "vi"
                    ? "Có lỗi xảy ra. Vui lòng thử lại sau."
                    : "An error occurred. Please try again later."
            );
        }
    };

    return (
        <ContactSection>
            <ContactContent>
                <ContentGrid>
                    <CompanyInfo>
                        <CompanyName>{t("company-name")}</CompanyName>

                        <ContactItem>
                            <FaMapMarkerAlt />
                            <span>{t("address")}</span>
                        </ContactItem>

                        <ContactItem>
                            <FaPhone />
                            <Link href={`tel:${t("phone")}`}> {t("phone")}</Link>
                        </ContactItem>

                        <ContactItem>
                            <MdEmail />
                            <Link href="mailto:ctlamsanphutho@gmail.com">
                                ctlamsanphutho@gmail.com
                            </Link>
                        </ContactItem>

                        <ContactItem>
                            <FaFacebook />
                            <Link
                                href="https://www.facebook.com/profile.php?id=61568503735365"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Facebook Page
                            </Link>
                        </ContactItem>

                        <ContactItem>
                            <FaWhatsapp />
                            <span>WhatsApp: +84 396 246 882</span>
                        </ContactItem>

                        <ContactItem>
                            <SiZalo />
                            <span>Zalo: {t("phone")}</span>
                        </ContactItem>
                    </CompanyInfo>

                    <ConsultationForm onSubmit={handleSubmit}>
                        <FormTitle>
                            {i18n.language === "vi" ? "Liên hệ đặt hàng" : "Contact Order"}
                        </FormTitle>

                        <Input
                            type="text"
                            name="name"
                            placeholder={i18n.language === "vi" ? "Họ và tên" : "Full Name"}
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />

                        <Input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />

                        <Input
                            type="tel"
                            name="phone"
                            placeholder={i18n.language === "vi" ? "Số điện thoại" : "Phone Number"}
                            value={formData.phone}
                            onChange={handleChange}
                            required
                        />

                        <Input
                            type="datetime-local"
                            name="preferredDate"
                            value={formData.preferredDate}
                            onChange={handleChange}
                            required
                        />

                        <TextArea
                            name="message"
                            placeholder={
                                i18n.language === "vi" ? "Nội dung cần tư vấn..." : "Message..."
                            }
                            value={formData.message}
                            onChange={handleChange}
                            required
                        />

                        <SubmitButton type="submit">
                            {" "}
                            {i18n.language === "vi" ? "Gửi yêu cầu" : "Send"}
                        </SubmitButton>
                    </ConsultationForm>
                </ContentGrid>

                <MapContainer>
                    <iframe
                        title="Google Map Location of Lam San Phu Tho"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4504.220614922546!2d105.1700781!3d21.522112499999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x313498d739ad2341%3A0xb8ee9270b8b8f9e4!2zRzVDQytSMlcsIFRoYW5oIEJhLCBQaMO6IFRo4buN!5e1!3m2!1sen!2s!4v1732333015877!5m2!1sen!2s"
                        width="100%"
                        height="100%"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                </MapContainer>
            </ContactContent>
        </ContactSection>
    );
};

export default Contact;

const ContactSection = styled.section`
    padding: 4rem 0;
    background: #fff;
`;

const ContactContent = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
`;

const ContentGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    margin-bottom: 3rem;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
`;

const CompanyInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`;

const CompanyName = styled.h2`
    font-size: 1.8rem;
    font-weight: bold;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;

    &:after {
        content: "";
        display: block;
        width: 80px;
        height: 3px;
        background-color: white;
        margin-top: 0.5rem;
    }

    @media (max-width: 768px) {
        font-size: 1.5rem;

        &:after {
            width: 60px;
            height: 2px;
        }
    }
`;

const ContactItem = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.5rem 0;
    transition: all 0.3s ease;
    color: #333;

    &:hover {
        transform: translateX(5px);
        color: #16a34a;
    }

    svg {
        font-size: 1.2rem;
        min-width: 1.2rem;
        color: #16a34a;
    }
`;

const Link = styled.a`
    color: #333;
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
        color: #16a34a;
    }
`;

const ConsultationForm = styled.form`
    background: #f8f9fa;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const FormTitle = styled.h3`
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #16a34a;
    font-weight: bold;
`;

const Input = styled.input`
    padding: 0.75rem;
    border-radius: 4px;
    border: 1px solid #ddd;
    width: 100%;

    &:focus {
        outline: none;
        border-color: #16a34a;
        box-shadow: 0 0 0 2px rgba(22, 163, 74, 0.1);
    }
`;

const TextArea = styled.textarea`
    padding: 0.75rem;
    border-radius: 4px;
    border: 1px solid #ddd;
    width: 100%;
    min-height: 100px;
    resize: vertical;

    &:focus {
        outline: none;
        border-color: #16a34a;
        box-shadow: 0 0 0 2px rgba(22, 163, 74, 0.1);
    }
`;

const SubmitButton = styled.button`
    padding: 0.75rem 1.5rem;
    background-color: #16a34a;
    color: white;
    border: none;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        background-color: #15803d;
        transform: translateY(-2px);
    }
`;

const MapContainer = styled.div`
    width: 100%;
    height: 400px;
    margin-top: 3rem;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;
