import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Footer from "../components/Footer";
import ttxk from "./../assets/images/Bản đồ thị trường xuất khẩu.png";
import nmsx from "./../assets/images/Ảnh nhà máy.png";

const AboutUs = () => {
    const { t, i18n } = useTranslation();
    return (
        <PageContainer>
            <HeaderSection className="relative bg-gradient-to-b from-green-400 to-green-800">
                <HeaderContent>
                    <HeaderTitle>{t("company-name")}</HeaderTitle>
                </HeaderContent>
            </HeaderSection>

            <MainContent>
                <Section>
                    <SectionTitle>{t("about-us")}</SectionTitle>
                    <IntroBox>
                        <IntroText>
                            <strong>
                                <i>
                                    {i18n.language === "vi"
                                        ? `Công ty Sản xuất và Kinh doanh Lâm sản Phú Thọ (Công ty Lâm sản Phú Thọ)`
                                        : `Phu Tho Wood Manufacturing and Trading Limited Company (Phu Tho Wood Company) 
                                    `}
                                </i>
                            </strong>{" "}
                            {i18n.language === "vi"
                                ? `
                            là đơn vị tiên phong trong lĩnh vực chế biến và cung ứng sản phẩm gỗ
                            công nghiệp tại Việt Nam. Tọa lạc tại Khu Cây Xa, Xã Quảng Yên, Huyện
                            Thanh Ba, Tỉnh Phú Thọ, chúng tôi tự hào là một trong những nhà sản xuất
                            ván ép hàng đầu trong nước.
                            `
                                : `
                            is a leading unit in the field of processing and supplying industrial wood 
                            products in Vietnam. Located in Cay Xa Area, Quang Yen Commute, Thanh Ba District, 
                            Phu Tho Province, we are proud to be one of the leading units in the field of 
                            plywood manufacture in Vietnam.
                            `}
                        </IntroText>
                    </IntroBox>
                </Section>

                <Section>
                    <SectionTitle>
                        {i18n.language === "vi" ? "Thị Trường Xuất Khẩu" : "Export Market"}
                    </SectionTitle>
                    <MarketsContainer>
                        <MarketsGrid>
                            {[
                                {
                                    country: i18n.language === "vi" ? "Nhật Bản" : "Japan",
                                    flag: "🇯🇵",
                                    description:
                                        i18n.language === "vi"
                                            ? "Thị trường chính"
                                            : "Primary market",
                                },
                                {
                                    country: i18n.language === "vi" ? "Hàn Quốc" : "South Korea",
                                    flag: "🇰🇷",
                                    description:
                                        i18n.language === "vi"
                                            ? "Đối tác chiến lược"
                                            : "Strategic partner",
                                },
                                {
                                    country: i18n.language === "vi" ? "Malaysia" : "Malaysia",
                                    flag: "🇲🇾",
                                    description:
                                        i18n.language === "vi"
                                            ? "Thị trường mới nổi"
                                            : "Emerging market",
                                },
                                {
                                    country: i18n.language === "vi" ? "Singapore" : "Singapore",
                                    flag: "🇸🇬",
                                    description:
                                        i18n.language === "vi"
                                            ? "Đối tác tiềm năng"
                                            : "Potential partner",
                                },
                            ].map((market, index) => (
                                <MarketCard key={index}>
                                    <div
                                        style={{
                                            fontSize: "2.25rem",
                                            marginBottom: "0.75rem",
                                            textAlign: "center",
                                        }}
                                    >
                                        {market.flag}
                                    </div>
                                    <h4
                                        style={{
                                            fontSize: "1.125rem",
                                            fontWeight: "600",
                                            color: "#1f2937",
                                            textAlign: "center",
                                            marginBottom: "0.5rem",
                                        }}
                                    >
                                        {market.country}
                                    </h4>
                                    <p
                                        style={{
                                            color: "#4b5563",
                                            fontSize: "0.875rem",
                                            textAlign: "center",
                                        }}
                                    >
                                        {market.description}
                                    </p>
                                </MarketCard>
                            ))}
                        </MarketsGrid>
                        <div className="-mx-10 md:mx-0">
                            <ProductionImage src={ttxk} alt="Thị trường xuất khẩu" />
                        </div>
                    </MarketsContainer>
                </Section>

                <Section>
                    <SectionTitle>
                        {i18n.language === "vi"
                            ? "Công Nghệ & Sản Xuất"
                            : "Technology & Manufacture"}
                    </SectionTitle>
                    <ProductionSection style={{ paddingTop: 0 }}>
                        <ProductionGrid>
                            <div className="-mx-5 md:mx-0">
                                <ProductionImage
                                    style={{
                                        borderBottomLeftRadius: 0,
                                        borderBottomRightRadius: 0,
                                    }}
                                    src={nmsx}
                                    alt="Nhà máy sản xuất"
                                />
                            </div>
                            <div>
                                <p
                                    style={{
                                        color: "#374151",
                                        fontWeight: "600",
                                        marginBottom: "1.5rem",
                                    }}
                                >
                                    {i18n.language === "vi"
                                        ? `
                                    Với quyết tâm nâng cao chất lượng sản phẩm và bảo vệ môi trường,
                                    chúng tôi liên tục đầu tư vào:
                                    `
                                        : `
                                    With a view to enhancing product quality and protecting the environment, 
                                    we always invest in:
                                    `}
                                </p>
                                {[
                                    ...(i18n.language === "en"
                                        ? [
                                              "Advanced production technology",
                                              "Modern production processes",
                                              "Strict quality management system",
                                              "Environmentally friendly solutions",
                                          ]
                                        : [
                                              "Công nghệ sản xuất tiên tiến",
                                              "Quy trình sản xuất hiện đại",
                                              "Hệ thống quản lý chất lượng nghiêm ngặt",
                                              "Giải pháp thân thiện với môi trường",
                                          ]),
                                ].map((item, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            backgroundColor: "white",
                                            padding: "1rem",
                                            borderRadius: "0.5rem",
                                            marginBottom: "1rem",
                                            boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "2rem",
                                                height: "2rem",
                                                backgroundColor: "#166534",
                                                borderRadius: "9999px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                marginRight: "1rem",
                                            }}
                                        >
                                            <span style={{ color: "white", fontWeight: "bold" }}>
                                                {index + 1}
                                            </span>
                                        </div>
                                        <p style={{ color: "#374151" }}>{item}</p>
                                    </div>
                                ))}
                            </div>
                        </ProductionGrid>
                    </ProductionSection>
                </Section>

                <Section>
                    <SectionTitle>
                        {i18n.language === "vi" ? "Tầm Nhìn & Sứ Mệnh" : "Vision & Mission"}
                    </SectionTitle>
                    <div
                        className="p-5 md:p-10"
                        style={{
                            background: "linear-gradient(to bottom right, #e6f3ff, #d1fae5)",
                            borderRadius: "0.75rem",
                            boxShadow: "0 6px 8px rgba(0, 0, 0, 0.1)",
                            position: "relative",
                            overflow: "hidden",
                        }}
                    >
                        {/* Subtle background effect */}
                        <div
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                background:
                                    "radial-gradient(circle at top left, rgba(255,255,255,0.2) 0%, transparent 50%)",
                                pointerEvents: "none",
                            }}
                        />

                        <div style={{ position: "relative", zIndex: 1 }}>
                            <p
                                style={{
                                    color: "#1f2937",
                                    fontSize: "1.125rem",
                                    marginBottom: "2rem",
                                    textAlign: "center",
                                    fontWeight: "600",
                                }}
                            >
                                {i18n.language === "vi"
                                    ? "Chúng tôi cam kết xây dựng và phát triển theo những mục tiêu:"
                                    : "We are committed to building and developing according to these goals:"}
                            </p>

                            {[
                                ...(i18n.language === "vi"
                                    ? [
                                          "Xây dựng thương hiệu mạnh mẽ trên thị trường quốc tế",
                                          "Mở rộng xuất khẩu ra thị trường toàn cầu",
                                          "Cung cấp sản phẩm đạt tiêu chuẩn quốc tế",
                                          "Phát triển bền vững và thân thiện với môi trường",
                                      ]
                                    : [
                                          "Build a strong brand in the international market",
                                          "Expand exports to global markets",
                                          "Provide products that meet international standards",
                                          "Develop sustainably and environmentally friendly",
                                      ]),
                            ].map((goal, index) => (
                                <div
                                    key={index}
                                    className="transform transition duration-300 hover:scale-105 hover:shadow-lg"
                                    style={{
                                        backgroundColor: "white",
                                        padding: "1.25rem",
                                        borderRadius: "0.75rem",
                                        marginBottom: "1rem",
                                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "2.5rem",
                                            height: "2.5rem",
                                            backgroundColor: "#166534",
                                            borderRadius: "9999px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            marginRight: "1rem",
                                            flexShrink: 0,
                                        }}
                                    >
                                        <span style={{ color: "white", fontWeight: "bold" }}>
                                            {index + 1}
                                        </span>
                                    </div>
                                    <p style={{ color: "#374151", flex: 1 }}>{goal}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </Section>

                <Section>
                    <SectionTitle>
                        {i18n.language === "vi" ? "Cam Kết Chất Lượng" : "Quality Commission"}
                    </SectionTitle>
                    <div
                        className="p-5 md:p-10"
                        style={{
                            background: "linear-gradient(to bottom right, #d1fae5, #dcfce7)",
                            borderRadius: "0.75rem",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                        }}
                    >
                        <div style={{ textAlign: "center", marginBottom: "2rem" }}>
                            <p style={{ color: "#374151", fontSize: "1.125rem" }}>
                                {i18n.language === "vi"
                                    ? "Với đội ngũ nhân viên chuyên nghiệp và tận tâm, chúng tôi cam kết:"
                                    : "With a team of professional and dedicated staff, we are committed to providing products which are:"}
                            </p>
                        </div>
                        <CommitmentGrid>
                            {[
                                {
                                    title:
                                        i18n.language === "vi"
                                            ? "Sản phẩm bền đẹp"
                                            : "Durable and aesthetic",
                                    icon: (
                                        <svg
                                            className="w-8 h-8"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M5 13l4 4L19 7"
                                            />
                                        </svg>
                                    ),
                                },
                                {
                                    title:
                                        i18n.language === "vi"
                                            ? "Thân thiện với môi trường"
                                            : "Environmentally friendly",
                                    icon: (
                                        <svg
                                            className="w-8 h-8"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                                            />
                                        </svg>
                                    ),
                                },
                                {
                                    title:
                                        i18n.language === "vi"
                                            ? "Đáp ứng các tiêu chuẩn quốc tế"
                                            : "Internationally-standardized",
                                    icon: (
                                        <svg
                                            className="w-8 h-8"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                    ),
                                },
                                {
                                    title:
                                        i18n.language === "vi"
                                            ? "Dịch vụ khách hàng xuất sắc"
                                            : "Excellent customer service",
                                    icon: (
                                        <svg
                                            className="w-8 h-8"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
                                            />
                                        </svg>
                                    ),
                                },
                            ].map((item, index) => (
                                <div
                                    key={index}
                                    className="bg-white px-1.5 py-3 lg:p-6 rounded-xl shadow-md hover:shadow-lg transform hover:-translate-y-1 transition-transform duration-200"
                                >
                                    <div className="flex flex-col items-center text-center">
                                        <div className="text-green-600 mb-4">{item.icon}</div>
                                        <h4 className="text-lg font-semibold text-gray-800">
                                            {item.title}
                                        </h4>
                                    </div>
                                </div>
                            ))}
                        </CommitmentGrid>
                    </div>
                </Section>
            </MainContent>

            {/* Footer section remains the same */}
            <div className="bg-green-100 py-8">
                <div className="max-w-6xl mx-auto px-4 text-center">
                    <h2 className="text-2xl font-semibold text-green-800 mb-2">
                        {i18n.language === "en"
                            ? "PHU THO WOOD PROCESSING AND TRADING COMPANY"
                            : "CÔNG TY SẢN XUẤT VÀ KINH DOANH LÂM SẢN PHÚ THỌ"}
                    </h2>
                    <p className="text-green-600 text-lg italic">
                        {i18n.language === "en"
                            ? "We are pleased to collaborate with you!"
                            : "Hân hạnh được hợp tác với quý khách hàng!"}
                    </p>
                </div>
            </div>
            <Footer />
        </PageContainer>
    );
};

export default AboutUs;

const PageContainer = styled.div`
    min-height: 100vh;
    background-color: white;
`;

const HeaderSection = styled.div`
    // background-color: #166534;
    padding: 4rem 1rem;

    @media (max-width: 768px) {
        padding: 2rem 1rem 1.2rem;
    }
`;

const HeaderContent = styled.div`
    max-width: 72rem;
    margin: 0 auto;
    padding: 0 1rem;
`;

const HeaderTitle = styled.h1`
    font-size: 2.25rem;
    font-weight: bold;
    color: white;
    text-align: center;

    @media (max-width: 768px) {
        font-size: 1.75rem;
    }
`;

const MainContent = styled.div`
    max-width: 72rem;
    margin: 0 auto;
    padding: 3rem 1rem;
`;

const Section = styled.section`
    margin-bottom: 4rem;
`;

const SectionTitle = styled.h2`
    font-size: 1.5rem;
    font-weight: bold;
    color: #166534;
    margin-bottom: 1.5rem;
`;

const IntroBox = styled.div`
    background-color: #d1fae5;
    padding: 2rem;
    border-radius: 0.5rem;

    @media (max-width: 768px) {
        padding: 1rem;
    }
`;

const IntroText = styled.p`
    color: #374151;
    line-height: 1.75;
`;

const MarketsContainer = styled.div`
    background: linear-gradient(to right, #d1fae5, #dcfce7);
    border-radius: 0.75rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
`;

const MarketsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    padding: 2rem;

    @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        padding: 1rem;
    }
`;

const MarketCard = styled.div`
    background-color: white;
    padding: 1.5rem;
    border-radius: 0.75rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s;

    &:hover {
        transform: translateY(-0.25rem);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    @media (max-width: 768px) {
        padding: 0.7rem 0.5rem;
    }
`;

const ProductionSection = styled.div`
    background-color: #d1fae5;
    padding: 2rem;
    border-radius: 0.75rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        padding: 1.25rem;
    }
`;

const ProductionGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    align-items: center;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

const ProductionImage = styled.img`
    width: 100%;
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const CommitmentGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;

    @media (max-width: 1024px) {
        grid-template-columns: repeat(2, 1fr);
    }

    // @media (max-width: 640px) {
    //     grid-template-columns: 1fr;
    // }
`;
