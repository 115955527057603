import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";
import AboutUsHome from "../components/home/about-us";
import ProductsHome from "../components/home/products";
// import Banner from "../components/Banner";

const Home = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className="flex flex-col gap-10 items-center text-gray-600 min-h-screen pb-40 select-none">
                {/* <Banner /> */}
                <img
                    src={t("cover-image")}
                    alt="Cover"
                    className="w-full h-full object-contain transition-opacity duration-500 ease-in-out"
                />
                <AboutUsHome />
                {/* <div className="flex flex-col gap-3 w-full items-center md:5/6 lg:w-4/5 max-w-4xl">
                <div className="session-title w-11/12">
                    <b />
                    <p className="hvr-underline-from-center">{t("about-us")}</p>
                    <b />
                </div>
                <div className="w-11/12">
                        <span className="font-bold text-lg pl-10">
                            Công ty Sản xuất và Kinh doanh Lâm sản Phú Thọ
                        </span>
                        &nbsp;
                        <span className=" text-[#111]">
                            là đơn vị hàng đầu trong lĩnh vực chế biến và cung ứng sản phẩm từ gỗ
                            tại Việt Nam. Với nhiều năm kinh nghiệm, công ty chuyên sản xuất và xuất
                            khẩu các loại ván ép như: Ván ép bao bì, ván ép chà dán, ván ép phủ
                            phim, ván ép LVL,... Chúng tôi cam kết mang đến cho khách hàng những sản
                            phẩm bền đẹp, thân thiện với môi trường và đáp ứng các tiêu chuẩn quốc
                            tế. Đội ngũ nhân viên tận tâm, cùng quy trình sản xuất hiện đại, Công ty
                            Phú Thọ không ngừng đổi mới và phát triển, hướng tới mục tiêu trở thành
                            thương hiệu tin cậy trong ngành lâm sản.
                        </span>
                </div>
                <div id="statistic" className="flex w-full justify-around statistic-row">
                    <div className="flex flex-col items-center">
                        <div className="rounded-full bg-[#f57923] p-3">
                            <IoTime color="white" size={28} />
                        </div>
                        <h4 className="font-semibold md:text-lg">
                            <span id="experienceCount">0</span>+
                        </h4>
                        <h5 className="uppercase text-[#333] text-sm">năm kinh nghiệm</h5>
                    </div>
                    <div className="flex flex-col items-center">
                        <div className="rounded-full bg-[#f57923] p-3">
                            <IoTimer color="white" size={28} />
                        </div>
                        <h4 className="font-semibold md:text-lg">
                            <span id="capacityCount">0</span> m<sup>3</sup>/năm
                        </h4>
                        <h5 className="uppercase text-[#333] text-sm">công suất nhà máy</h5>
                    </div>
                    <div className="flex flex-col items-center">
                        <div className="rounded-full bg-[#f57923] p-3">
                            <MdFactory color="white" size={28} />
                        </div>
                        <h4 className="font-semibold md:text-lg">
                            <span id="areaCount">0</span> m<sup>2</sup>
                        </h4>
                        <h5 className="uppercase text-[#333] text-sm">diện tích nhà máy</h5>
                    </div>
                </div>
            </div> */}
                <ProductsHome />
            </div>
            <Footer />
        </>
    );
};

export default Home;
