import { useTranslation } from "react-i18next";
import styled from "styled-components";

const DetailSection = () => {
    const { t, i18n } = useTranslation();
    return (
        <DetailWrapper>
            <DetailHeader>{t("product-details")}</DetailHeader>
            <DetailContent>
                <Section>
                    <SectionTitle>
                        {i18n.language === "vi" ? "Giới thiệu" : "Introduction"}
                    </SectionTitle>
                    <p className="pl-4 text-gray-600 leading-relaxed">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {i18n.language === "vi" ? (
                            <>
                                <strong>Ván ép LVL (Laminated Veneer Lumber)</strong> là một loại
                                vật liệu xây dựng và nội thất cao cấp, LVL được sản xuất bằng cách
                                ép các lớp gỗ mỏng theo phương pháp tiên tiến. Nhờ quy trình sản
                                xuất đặc biệt, LVL có khả năng chịu lực tốt, chống cong vênh và đảm
                                bảo độ bền lâu dài. Sản phẩm của chúng tôi đáp ứng các tiêu chuẩn
                                quốc tế, là lựa chọn lý tưởng cho những dự án yêu cầu chất lượng và
                                độ ổn định cao.
                            </>
                        ) : (
                            <>
                                <strong>LVL (Laminated Veneer Lumber)</strong> is a high-end
                                construction and interior material, produced by pressing thin layers
                                of wood using advanced methods. Thanks to the special production
                                process, LVL has good load-bearing capacity, is resistant to
                                warping, and ensures long-term durability. Our products meet
                                international standards, making them an ideal choice for projects
                                requiring high quality and stability.
                            </>
                        )}
                    </p>
                </Section>

                <Section>
                    <SectionTitle>{t("product-information")}</SectionTitle>
                    <TableWrapper>
                        <Table>
                            <tbody>
                                <Tr>
                                    <TdLabel>{t("measurement")}</TdLabel>
                                    <TdContent>
                                        {i18n.language === "vi"
                                            ? "Theo yêu cầu của khách hàng"
                                            : "Up to order"}
                                    </TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("popular-thickness")}</TdLabel>
                                    <TdContent>2.5 - 120mm</TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("tolerance")}</TdLabel>
                                    <TdContent>±0.5 - 1mm</TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("veneer-grade")}</TdLabel>
                                    <TdContent>
                                        {i18n.language === "vi"
                                            ? "Độn gỗ Thông, Keo, Bồ đề"
                                            : "Pine, Acacia, Styrax"}
                                    </TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("application")}</TdLabel>
                                    <TdContent>
                                        {i18n.language === "vi"
                                            ? "làm pallet đóng thùng, khung nội thất"
                                            : "Pallet packing, furniture frames"}
                                    </TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("glue")}</TdLabel>
                                    <TdContent>E0, E1, E2</TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("face")}</TdLabel>
                                    <TdContent>{i18n.language === "vi" ? "Không mặt" : "No Face"}</TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("density")}</TdLabel>
                                    <TdContent>
                                        550 - 580 kgs/m<sup>3</sup>
                                    </TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("moisture")}</TdLabel>
                                    <TdContent>
                                        {i18n.language === "vi" ? "Dưới" : "Under"} 18%
                                    </TdContent>
                                </Tr>
                            </tbody>
                        </Table>
                    </TableWrapper>
                </Section>

                <Section>
                    <SectionTitle>
                        {" "}
                        {i18n.language === "vi"
                            ? "Đặc điểm và tính năng của ván ép LVL"
                            : "Features and Characteristics of LVL Plywood"}
                    </SectionTitle>
                    <SectionContent>
                        <ContentTitle>
                            {i18n.language === "vi"
                                ? "Chất liệu và quy trình sản xuất"
                                : "Material and Manufacturing Process"}
                        </ContentTitle>
                        <ul className="content-list">
                            <li>
                                {i18n.language === "vi"
                                    ? "Ván ép LVL được sản xuất từ gỗ tự nhiên, chủ yếu là gỗ cứng, thông qua quy trình ép nhiều lớp gỗ mỏng được sắp xếp theo cùng một hướng. Quá trình này giúp ván có khả năng chịu lực tốt hơn so với gỗ nguyên khối. Sản phẩm được xử lý qua nhiều công đoạn tiên tiến để tăng cường khả năng chống mối mọt, ẩm mốc và duy trì tính ổn định kích thước trong các điều kiện môi trường khắc nghiệt."
                                    : "LVL plywood is made from natural wood, mainly hardwood, by pressing many thin layers of wood arranged in the same direction. This process gives the board better load-bearing capacity than solid wood."}
                            </li>
                            <li>
                                {i18n.language === "vi"
                                    ? "Sản phẩm được xử lý qua nhiều công đoạn tiên tiến để tăng cường khả năng chống mối mọt, ẩm mốc và duy trì tính ổn định kích thước trong các điều kiện môi trường khắc nghiệt."
                                    : "The product is processed through many advanced stages to enhance resistance to termites, and mold and maintain dimensional stability in harsh environmental conditions."}
                            </li>
                        </ul>

                        <ContentTitle>
                            {i18n.language === "vi"
                                ? "Độ bền và khả năng chịu lực"
                                : "Durability and Load-bearing Capacity"}
                        </ContentTitle>
                        <ul className="content-list">
                            <li>
                                {i18n.language === "vi"
                                    ? "Ván ép LVL có độ cứng cáp vượt trội, rất phù hợp cho các cấu trúc chịu lực lớn như khung nhà, giàn giáo và các công trình xây dựng khác. Sản phẩm không bị cong vênh hay nứt gãy, đảm bảo khả năng sử dụng lâu dài mà không cần thay thế hoặc sửa chữa thường xuyên."
                                    : "LVL plywood has outstanding strength and is very suitable for heavy load-bearing structures such as house frames, scaffolding, and other construction works."}
                            </li>
                            <li>
                                {i18n.language === "vi"
                                    ? "Sản phẩm không bị cong vênh hay nứt gãy, đảm bảo khả năng sử dụng lâu dài mà không cần thay thế hoặc sửa chữa thường xuyên."
                                    : "The product does not warp or crack, ensuring long-term use without frequent replacement or repair."}
                            </li>
                        </ul>

                        <ContentTitle>
                            {i18n.language === "vi"
                                ? "Độ chính xác về kích thước"
                                : "Exacity of measurement"}
                        </ContentTitle>
                        <p className="content-text">
                            {i18n.language === "vi"
                                ? "Ván LVL của chúng tôi được sản xuất với độ chính xác cao về kích thước, đảm bảo không có sai số về chiều dài, chiều rộng hay độ dày, rất phù hợp cho việc thi công các công trình yêu cầu tính chính xác cao."
                                : "Our LVL boards are manufactured with high precision in size, ensuring no errors in length, width, or thickness, very suitable for construction projects requiring high precision."}
                        </p>

                        <ContentTitle>
                            {i18n.language === "vi"
                                ? "Tiêu chuẩn về môi trường và an toàn"
                                : "Environment and Safety standards:"}
                        </ContentTitle>
                        <ul className="content-list">
                            <li>
                                {i18n.language === "vi"
                                    ? "Ván LVL không chứa các chất hóa học độc hại, tuân thủ nghiêm ngặt các tiêu chuẩn về an toàn sức khỏe cho người sử dụng và thân thiện với môi trường."
                                    : "LVL plywood does not contain toxic chemicals, strictly complies with health and safety standards for users, and is environmentally friendly."}
                            </li>
                            <li>
                                {i18n.language === "vi"
                                    ? "Sản phẩm được sản xuất từ gỗ tái chế và gỗ rừng trồng, góp phần bảo vệ nguồn tài nguyên gỗ tự nhiên."
                                    : "Products are made from recycled wood and planted wood, contributing to the protection of natural wood resources."}
                            </li>
                        </ul>
                    </SectionContent>
                </Section>

                <Section>
                    <SectionTitle>
                        {i18n.language === "vi" ? "Ứng dụng thực tế" : "Applications"}
                    </SectionTitle>
                    <SectionContent>
                        <ContentTitle>
                            {i18n.language === "vi" ? "Trong xây dựng" : "Contructions"}
                        </ContentTitle>
                        <p className="content-text">
                            {i18n.language === "vi"
                                ? "Ván LVL được sử dụng phổ biến trong các công trình xây dựng như giàn giáo, khung nhà, cột và các cấu trúc chịu lực khác nhờ vào độ cứng cáp và khả năng chống biến dạng."
                                : "LVL plywood is commonly used in construction works such as scaffolding, house frames, columns, and other load-bearing structures thanks to their rigidity and resistance to deformation."}
                        </p>

                        <ContentTitle>
                            {i18n.language === "vi" ? "Trong nội thất" : "Furniture"}
                        </ContentTitle>
                        <p className="content-text">
                            {i18n.language === "vi"
                                ? "Sản phẩm cũng rất thích hợp để làm đồ nội thất như cửa, tủ, bàn ghế, sàn nhà và trần nhà, mang lại tính thẩm mỹ cao và khả năng sử dụng bền bỉ."
                                : "The product is also very suitable for making furniture such as doors, cabinets, tables, chairs, floors, and ceilings, providing high aesthetics and durable use."}
                        </p>

                        <ContentTitle>
                            {i18n.language === "vi" ? "Các công trình khác" : "Other aoplication"}
                        </ContentTitle>
                        <p className="content-text">
                            {i18n.language === "vi"
                                ? "Ngoài lĩnh vực xây dựng và nội thất, ván LVL còn được sử dụng trong sản xuất container, thùng hàng và các cấu trúc vận tải nhờ vào tính linh hoạt và độ bền của nó."
                                : "In addition to the construction and interior fields, LVL plywood is also used in the production of containers, cargo boxes, and transport structures thanks to its flexibility and durability."}
                        </p>
                    </SectionContent>
                </Section>
            </DetailContent>
        </DetailWrapper>
    );
};

export default DetailSection;

const DetailWrapper = styled.div`
    position: relative;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    padding: 24px;
    margin: 32px 0;
`;

const DetailHeader = styled.div`
    position: absolute;
    top: -20px;
    left: 16px;
    background-color: white;
    padding: 0 12px;
    font-size: 1.5rem;
    font-weight: 600;
    // text-transform: uppercase;
    color: #4a5568;
`;

const DetailContent = styled.div`
    margin-top: 16px;
`;

const Section = styled.section`
    margin-bottom: 24px;
`;

const SectionTitle = styled.h3`
    color: #2f855a;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 16px;
`;

const TableWrapper = styled.div`
    // width: 100%;
    margin: 0 -16px;
    // overflow-x: auto;

    @media (min-width: 768px) {
        margin: 0 auto;
    }
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;

    @media (min-width: 768px) {
        min-width: 500px;
        max-width: 750px;
        margin-left: auto;
        margin-right: auto;
    }
`;

const Tr = styled.tr`
    &:hover {
        background-color: #f8f9fa;
    }
`;

const TdLabel = styled.td`
    font-weight: bold;
    width: 30%;
    min-width: 145px;
    word-wrap: break-word;
    white-space: normal;
    // padding: 8px;

    @media (min-width: 768px) {
        width: 25%;
        min-width: 150px;
    }
`;

const TdContent = styled.td`
    width: 65%;
    word-wrap: break-word;
    white-space: normal;
    padding: 8px;

    @media (min-width: 768px) {
        width: 75%;
    }
`;

const SectionContent = styled.div`
    .content-text {
        color: #4b5563; // equivalent to text-gray-600
        margin-left: 1rem; // equivalent to ml-4
        margin-bottom: 1rem; // equivalent to mb-4
        line-height: 1.625; // equivalent to leading-relaxed
    }

    .content-list {
        list-style-type: disc;
        list-style-position: outside;
        margin-left: 1rem;
        margin-bottom: 1rem;
        color: #4b5563;

        li {
            margin-bottom: 0.5rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
`;

const ContentTitle = styled.h4`
    font-weight: 600;
    color: #374151;
    margin-bottom: 5px;
    margin-left: 10px;
`;
