import { useTranslation } from "react-i18next";
import { FaFacebook, FaWhatsapp } from "react-icons/fa";
import { LuPhoneCall } from "react-icons/lu";
import { RxDoubleArrowUp } from "react-icons/rx";
import { SiZalo } from "react-icons/si";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";

const RightSidebar = () => {
    const { i18n } = useTranslation();
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <div className="fixed right-4 top-1/2 -translate-y-1/2 flex flex-col gap-3 z-50">
            {/* Facebook */}
            <div className="group relative flex items-center">
                <span className="absolute right-full mr-3 px-3 py-1 bg-blue-600 text-white text-sm rounded-lg opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
                    Facebook Fanpage PT Plywood
                </span>
                <PulseIconButton $animation={facebookPulse}>
                    <Link
                        href="https://www.facebook.com/profile.php?id=61568503735365"
                        className="w-10 h-10 bg-blue-600 rounded-full flex items-center justify-center hover:bg-blue-700 transition-colors"
                    >
                        <FaFacebook className="w-6 h-6 text-white" />
                    </Link>
                </PulseIconButton>
            </div>

            {/* Zalo */}
            <div className="group relative flex items-center">
                <span className="absolute right-full mr-3 px-3 py-1 bg-blue-500 text-white text-sm rounded-lg opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
                    {i18n.language === "en"
                        ? "Chat with us on Zalo: +84 396 246 882"
                        : "Nhắn tin với chúng tôi qua Zalo: +84 396 246 882"}
                </span>
                <PulseIconButton $animation={zaloPulse}>
                    <div className="w-10 h-10 bg-blue-500 rounded-full flex items-center justify-center hover:bg-blue-600 transition-colors">
                        <SiZalo className="w-6 h-6 text-white" />
                    </div>
                </PulseIconButton>
            </div>

            {/* WhatsApp */}
            <div className="group relative flex items-center">
                <span className="absolute right-full mr-3 px-3 py-1 bg-green-500 text-white text-sm rounded-lg opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
                    {i18n.language === "en"
                        ? "Message us on WhatsApp: +84 396 246 882"
                        : "Để lại tin nhắn qua WhatsApp: +84 396 246 882"}
                </span>
                <PulseIconButton $animation={whatsappPulse}>
                    <div className="w-10 h-10 bg-green-500 rounded-full flex items-center justify-center hover:bg-green-600 transition-colors">
                        <FaWhatsapp className="w-6 h-6 text-white" />
                    </div>
                </PulseIconButton>
            </div>

            {/* Phone */}
            <div className="group relative flex items-center">
                <span className="absolute right-full mr-3 px-3 py-1 bg-red-500 text-white text-sm rounded-lg opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
                    {i18n.language === "en"
                        ? "Call us now: +84 396 246 882"
                        : "Gọi ngay: +84 396 246 882"}
                </span>
                <Link
                    href="tel:+84 396 246 882"
                    className="w-10 h-10 bg-red-500 rounded-full flex items-center justify-center hover:bg-red-600 transition-colors animate-shake"
                >
                    <LuPhoneCall className="w-6 h-6 text-white" />
                </Link>
            </div>

            {/* Scroll to Top Button */}
            <div className="group relative flex items-center mt-20">
                <span className="absolute right-full mr-3 px-3 py-1 bg-gray-700 text-white text-sm rounded-lg opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
                    {i18n.language === "en" ? "Scroll to top" : "Về đầu trang"}
                </span>
                <button
                    onClick={scrollToTop}
                    className="w-10 h-10 flex items-center justify-center transition-transform animate-float"
                >
                    <RxDoubleArrowUp className="w-10 h-10 text-gray-600 hover:text-gray-800" />
                </button>
            </div>
        </div>
    );
};

export default RightSidebar;

const facebookPulse = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(59, 130, 246, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 6px rgba(59, 130, 246, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(59, 130, 246, 0);
  }
`;

const zaloPulse = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(59, 130, 246, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 6px rgba(59, 130, 246, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(59, 130, 246, 0);
  }
`;

const whatsappPulse = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(34, 197, 94, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 6px rgba(34, 197, 94, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(34, 197, 94, 0);
  }
`;

const PulseIconButton = styled.div`
    border-radius: 50%;
    animation: ${(props) => props.$animation} 2s infinite;
`;
