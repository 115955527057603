import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";

const DetailSection = () => {
    const { t, i18n } = useTranslation();
    return (
        <DetailWrapper>
            <DetailHeader>{t("product-details")}</DetailHeader>
            <DetailContent>
                <Section>
                    <SectionTitle>
                        {i18n.language === "vi"
                            ? "Giới thiệu ván ép Birch"
                            : "Introduction to Birch Plywood"}
                    </SectionTitle>
                    <p className="pl-4 text-gray-600 leading-relaxed">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {i18n.language === "vi" ? (
                            <>
                                <strong>Ván ép Birch, hay còn gọi là ván ép Bạch Dương</strong> là
                                sản phẩm cao cấp được làm từ gỗ Birch tự nhiên, với đặc tính bền đẹp
                                và khả năng chịu lực tốt. Tại PhuTho Wood, chúng tôi cung cấp nhiều
                                loại ván ép Birch đạt tiêu chuẩn, phục vụ cho mọi nhu cầu trong các
                                lĩnh vực nội thất, xây dựng và trang trí.
                            </>
                        ) : (
                            <>
                                <strong>Birch plywood</strong>, also known as Birch veneer plywood,
                                is a premium product made from natural Birch wood, characterized by
                                its durability, aesthetic appeal, and excellent load-bearing
                                capacity. At PhuTho Wood, we provide a wide range of high-standard
                                Birch plywood to meet diverse needs in interior design,
                                construction, and decoration.
                            </>
                        )}
                    </p>
                </Section>

                <Section>
                    <SectionTitle>{t("product-information")}</SectionTitle>
                    <TableWrapper>
                        <Table>
                            <tbody>
                                <Tr>
                                    <TdLabel>{t("measurement")}</TdLabel>
                                    <TdContent>
                                        {i18n.language === "vi"
                                            ? "4x8, 3x6 ft hoặc theo yêu cầu của khách hàng"
                                            : "4x8, 3x6 ft or as requested"}
                                    </TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("popular-thickness")}</TdLabel>
                                    <TdContent>3 - 30mm</TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("tolerance")}</TdLabel>
                                    <TdContent>±0.3 - 0.5mm</TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("species")}</TdLabel>
                                    <TdContent>
                                        {i18n.language === "vi"
                                            ? "Gỗ Bạch đàn, Keo"
                                            : "Eucalyptus, Acacia"}
                                    </TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("application")}</TdLabel>
                                    <TdContent>
                                        {i18n.language === "vi"
                                            ? "Nội thất cao cấp, xây dựng, cabinet"
                                            : "High-qualified furniture, construction, cabinet "}
                                    </TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("glue")}</TdLabel>
                                    <TdContent>E0 Carb P2</TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("face")}</TdLabel>
                                    <TdContent>Birch C, D, D+, E</TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("technology-information")}</TdLabel>
                                    <TdContent>
                                        <p>
                                            {i18n.language === "vi"
                                                ? "2 lần ép nóng"
                                                : "2 hot pressing times"}
                                        </p>
                                        <p>
                                            {i18n.language === "vi"
                                                ? "3 lần chà"
                                                : "3 sanding times"}
                                        </p>
                                    </TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("density")}</TdLabel>
                                    <TdContent>
                                        560 - 650 kgs/m<sup>3</sup>
                                    </TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("moisture")}</TdLabel>
                                    <TdContent>
                                        {i18n.language === "vi" ? "Dưới" : "Under"} 12%
                                    </TdContent>
                                </Tr>
                            </tbody>
                        </Table>
                    </TableWrapper>
                </Section>

                <Section>
                    <SectionTitle>
                        {i18n.language === "vi"
                            ? "Đặc điểm và tính năng của ván ép Birch"
                            : "Features and Characteristics of Birch Plywood"}
                    </SectionTitle>
                    <SectionContent>
                        <ContentTitle>
                            {i18n.language === "vi" ? "Độ bền cao" : "High Durability"}
                        </ContentTitle>
                        <p className="content-text">
                            {i18n.language === "vi"
                                ? "Ván ép Birch được tạo thành từ nhiều lớp gỗ Bạch Dương ép chặt với nhau, tạo ra kết cấu vững chắc, có khả năng chịu lực và chống cong vênh hiệu quả, phù hợp cho các ứng dụng trong nội thất và xây dựng."
                                : "Birch plywood consists of multiple layers of Birch wood tightly pressed together, creating a solid structure with excellent load resistance and anti-warping properties, making it suitable for furniture and construction applications."}
                        </p>

                        <ContentTitle>
                            {i18n.language === "vi"
                                ? "Bề mặt mịn và sáng"
                                : "Smooth and bright surface"}
                        </ContentTitle>
                        <p className="content-text">
                            {i18n.language === "vi"
                                ? "Ván ép Birch có bề mặt sáng mịn tự nhiên, rất phù hợp cho việc sơn, phủ laminate hoặc veneer, tạo ra các sản phẩm có tính thẩm mỹ cao."
                                : "Birch plywood has a naturally bright and smooth surface, ideal for painting, laminating, or veneering, resulting in products with high aesthetic value."}
                        </p>

                        <ContentTitle>
                            {i18n.language === "vi"
                                ? "Khả năng gia công tốt"
                                : "Excellent workability"}
                        </ContentTitle>
                        <p className="content-text">
                            {i18n.language === "vi"
                                ? "Loại ván này dễ dàng cắt, khoan và gia công, giúp thuận tiện trong quá trình sản xuất đồ gỗ nội thất, tủ kệ và các sản phẩm trang trí."
                                : "This type of plywood is easy to cut, drill, and process, facilitating the production of furniture, cabinets, and decorative items."}
                        </p>

                        <ContentTitle>
                            {i18n.language === "vi" ? "Khả năng chống ẩm" : "Moisture resistance"}
                        </ContentTitle>
                        <p className="content-text">
                            {i18n.language === "vi"
                                ? "Dù là gỗ tự nhiên, ván ép Birch có khả năng chống ẩm tốt hơn so với nhiều loại gỗ khác, đặc biệt khi được xử lý đúng cách, giúp sản phẩm bền lâu trong môi trường có độ ẩm cao."
                                : "Despite being natural wood, Birch plywood has better moisture resistance than many other types of wood, especially when properly treated, ensuring long-lasting performance in humid environments."}
                        </p>

                        <ContentTitle>
                            {i18n.language === "vi" ? "Thân thiện với môi trường" : "Eco-friendly"}
                        </ContentTitle>
                        <p className="content-text">
                            {i18n.language === "vi"
                                ? "Gỗ Bạch Dương là loại gỗ phát triển nhanh và bền vững, góp phần bảo vệ tài nguyên rừng trong sản xuất."
                                : "Birch trees grow rapidly and sustainably, contributing to forest resource preservation in the production process."}
                        </p>
                        <Conclusion>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {i18n.language === "vi" ? (
                                <>
                                    Với những đặc điểm nổi bật này, <strong>ván ép Birch</strong>{" "}
                                    tại{" "}
                                    <Link
                                        className="text-blue-600 underline font-semibold"
                                        to={"/"}
                                    >
                                        Lâm sản Phú Thọ
                                    </Link>{" "}
                                    là lựa chọn lý tưởng cho các ứng dụng cần sự bền vững, thẩm mỹ
                                    và độ chính xác cao.
                                </>
                            ) : (
                                <>
                                    With these outstanding features, <strong>Birch plywood</strong>{" "}
                                    from{" "}
                                    <Link className="text-blue-600 underline font-semibold" to="/">
                                        PhuTho Wood
                                    </Link>{" "}
                                    is an ideal choice for applications requiring sustainability,
                                    aesthetics, and high precision.
                                </>
                            )}
                        </Conclusion>
                    </SectionContent>
                </Section>

                <Section>
                    <SectionTitle>
                        {i18n.language === "vi"
                            ? "Ứng dụng của ván ép Birch"
                            : "Birch plywood Applications"}
                    </SectionTitle>
                    <SectionContent>
                        <ContentTitle>
                            {i18n.language === "vi" ? "Nội thất gia đình" : "Furniture"}
                        </ContentTitle>
                        <p className="content-text">
                            {i18n.language === "vi"
                                ? "Ván ép Birch được sử dụng phổ biến trong sản xuất nội thất như tủ, bàn, ghế, kệ sách và giường nhờ vào độ bền cao, bề mặt mịn và dễ xử lý."
                                : "Birch plywood is widely used in manufacturing furniture such as cabinets, tables, chairs, bookshelves, and beds, thanks to its high durability, smooth surface, and ease of handling."}
                        </p>
                        <ContentTitle>
                            {i18n.language === "vi" ? "Thiết kế kiến trúc" : "Architectural design"}
                        </ContentTitle>
                        <p className="content-text">
                            {i18n.language === "vi"
                                ? "Với khả năng chịu lực tốt và chống cong vênh, ván ép Birch là lựa chọn lý tưởng cho các công trình kiến trúc, từ ốp tường, trần nhà đến sàn."
                                : "With excellent loading resistance and anti-warping properties, Birch plywood is an ideal choice for architectural projects, from wall cladding and ceiling paneling to flooring."}
                        </p>
                        <ContentTitle>
                            {i18n.language === "vi" ? "Trang trí nội thất" : "Interior decoration"}
                        </ContentTitle>
                        <p className="content-text">
                            {i18n.language === "vi"
                                ? "Bề mặt sáng, mịn của ván ép Birch rất thích hợp cho việc phủ veneer, sơn hoặc xử lý bề mặt, giúp tạo ra các sản phẩm trang trí tinh tế và sang trọng cho không gian sống và làm việc."
                                : "The bright, smooth surface of Birch plywood is perfect for veneering, painting, or surface processing, creating refined and elegant decorative products for living and working spaces."}
                        </p>
                        <ContentTitle>
                            {i18n.language === "vi"
                                ? "Sản xuất đồ gỗ công nghiệp"
                                : "Industrial furniture manufacturing"}
                        </ContentTitle>
                        <p className="content-text">
                            {i18n.language === "vi"
                                ? "Trong ngành sản xuất đồ gỗ công nghiệp, ván ép Birch được ưa chuộng nhờ vào tính ổn định cao, dễ gia công và khả năng chống mối mọt."
                                : "In the industrial furniture industry, Birch plywood is favored for its high stability, ease of processing, and termite resistance."}
                        </p>
                        <ContentTitle>
                            {i18n.language === "vi"
                                ? "Đồ chơi và dụng cụ giáo dục"
                                : "Toys and educational tools"}
                        </ContentTitle>
                        <p className="content-text">
                            {i18n.language === "vi"
                                ? "Ván ép Birch, với độ an toàn và không chứa các chất độc hại, thường được sử dụng để sản xuất đồ chơi trẻ em, đồ dùng học tập và các dụng cụ giáo dục khác."
                                : "Birch plywood, being safe and free from harmful substances, is often used to manufacture children’s toys, educational tools, and other learning tools."}
                        </p>
                        <ContentTitle>
                            {i18n.language === "vi"
                                ? "Ngành đóng tàu và ô tô"
                                : "Marine and automotive industries"}
                        </ContentTitle>
                        <p className="content-text">
                            {i18n.language === "vi"
                                ? "Ván ép Birch có độ bền cao và khả năng chống ẩm tốt, thường được dùng để sản xuất các chi tiết nội thất cho tàu thuyền và xe ô tô."
                                : "With its high durability and moisture resistance, Birch plywood is commonly used for producing interior components for boats and automobiles."}
                        </p>

                        <Conclusion>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {i18n.language === "vi" ? (
                                <>
                                    Với sự đa dạng trong ứng dụng, <strong>ván ép Birch</strong> tại{" "}
                                    <Link
                                        className="text-blue-600 underline font-semibold"
                                        to={"/"}
                                    >
                                        Lâm sản Phú Thọ
                                    </Link>{" "}
                                    đáp ứng đầy đủ các yêu cầu kỹ thuật và thẩm mỹ cho nhiều lĩnh
                                    vực khác nhau.
                                </>
                            ) : (
                                <>
                                    With its versatility, <strong>Birch plywood</strong> from{" "}
                                    <Link
                                        className="text-blue-600 underline font-semibold"
                                        to={"/"}
                                    >
                                        PhuTho Wood
                                    </Link>{" "}
                                    meets the technical and aesthetic requirements across various
                                    industries.
                                </>
                            )}
                        </Conclusion>
                    </SectionContent>
                </Section>
            </DetailContent>
        </DetailWrapper>
    );
};

export default DetailSection;

const DetailWrapper = styled.div`
    position: relative;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    padding: 24px;
    margin: 32px 0;
`;

const DetailHeader = styled.div`
    position: absolute;
    top: -20px;
    left: 16px;
    background-color: white;
    padding: 0 12px;
    font-size: 1.5rem;
    font-weight: 600;
    // text-transform: uppercase;
    color: #4a5568;
`;

const DetailContent = styled.div`
    margin-top: 16px;
`;

const Section = styled.section`
    margin-bottom: 24px;
`;

const SectionTitle = styled.h3`
    color: #2f855a;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 16px;
`;
const TableWrapper = styled.div`
    // width: 100%;
    margin: 0 -16px;
    // overflow-x: auto;

    @media (min-width: 768px) {
        margin: 0 auto;
    }
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;

    @media (min-width: 768px) {
        min-width: 500px;
        max-width: 750px;
        margin-left: auto;
        margin-right: auto;
    }
`;

const Tr = styled.tr`
    &:hover {
        background-color: #f8f9fa;
    }
`;

const TdLabel = styled.td`
    font-weight: bold;
    width: 30%;
    min-width: 120px;
    word-wrap: break-word;
    white-space: normal;
    // padding: 8px;

    @media (min-width: 768px) {
        width: 25%;
        min-width: 150px;
    }
`;

const TdContent = styled.td`
    width: 65%;
    word-wrap: break-word;
    white-space: normal;
    padding: 8px;

    @media (min-width: 768px) {
        width: 75%;
    }
`;

const SectionContent = styled.div`
    .content-text {
        color: #4b5563; // equivalent to text-gray-600
        margin-left: 1rem; // equivalent to ml-4
        margin-bottom: 1rem; // equivalent to mb-4
        line-height: 1.625; // equivalent to leading-relaxed
    }

    .content-list {
        list-style-type: disc;
        list-style-position: outside;
        margin-left: 1rem;
        margin-bottom: 1rem;
        color: #4b5563;

        li {
            margin-bottom: 0.5rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
`;

const ContentTitle = styled.h4`
    font-weight: 600;
    color: #374151;
    margin-bottom: 5px;
    margin-left: 10px;
`;

const Conclusion = styled.p`
    position: relative;
    background: linear-gradient(to right, #f0f8ff, #e6f2ff);
    padding: 20px;
    border-radius: 8px;
    border-left: 4px solid #4299e1;
    font-size: 18px;
    font-style: italic;
    color: #2c5282;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    }

    &::before {
        content: "✓";
        position: absolute;
        top: 15px;
        left: -30px;
        background: #4299e1;
        color: white;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
    }
`;
