import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaLocationDot, FaPhone } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { IoMenuSharp } from "react-icons/io5";
import { MdClose } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import Logo from "./../assets/images/logo.com_c731fa8b-72b4-46db-ba02-d74a6f092470.svg";
// import Logo from "./../assets/images/logo.png";
import { throttle } from "lodash";
import { changeLanguage } from "../i18n";
import NameCompany from "./../assets/images/name_company.png";

// const NavBar = ({ changeLanguage }) => {
const NavBar = () => {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = throttle(() => {
            setIsScrolled(window.scrollY >= 100);
        }, 200); // Giảm tần suất gọi xuống mỗi 200ms

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    return (
        <header className="w-full">
            <div style={{ paddingTop: isScrolled ? "4rem" : "0" }}>
                <div
                    className={`bg-green-600 transition-all duration-300 ${
                        isScrolled ? "hidden" : "block"
                    }`}
                >
                    <TopHeader />
                </div>
                <div
                    className={`bg-white w-full shadow-md transition-all duration-300 ${
                        isScrolled ? "fixed top-0 left-0 z-50" : ""
                    }`}
                >
                    {/* <BottomHeader changeLanguage={changeLanguage} /> */}
                    <BottomHeader />
                </div>
            </div>
        </header>
    );
};

function TopHeader() {
    const { t, i18n } = useTranslation();

    const getShortAddress = () => {
        if (i18n.language === "vi") {
            return "Cây Xa, X.Quảng Yên, H.Thanh Ba, T.Phú Thọ, VN";
        }
        return "Cay Xa Area, Q.Yen Comm., T.Ba Dist., P.Tho Prov., VN";
    };

    return (
        <div className="flex flex-col lg:flex-row justify-around md:gap-4 items-center mx-auto py-2 text-white whitespace-nowrap">
            <div className="flex items-center gap-1 w-min">
                <FaLocationDot size={12} className="mt-[1px]" />
                <span className="lg:hidden">{getShortAddress()}</span>
                <span className="hidden lg:inline">{t("address")}</span>
            </div>
            <div className="min-w-full lg:min-w-96 w-full max-w-[420px] flex justify-around items-center">
                <div className="flex justify-around items-center gap-1">
                    <IoMdMail size={15} className="mt-[3px]" />
                    <a
                        href="mailto:ctlamsanphutho@gmail.com"
                        className="hover:underline transition-all ease-in-out duration-1000"
                    >
                        ctlamsanphutho@gmail.com
                    </a>
                </div>
                <span className="flex gap-1 items-center">
                    <FaPhone size={14} className="mt-0.5" />
                    <a
                        href={`tel:${t("phone")}`}
                        className="hover:underline transition-all ease-in-out duration-1000"
                    >
                        {t("phone")}
                    </a>
                </span>
            </div>
        </div>
    );
}

// function BottomHeader({ changeLanguage }) {
function BottomHeader() {
    const { t, i18n } = useTranslation();
    const sideNavRef = useRef(null);
    const location = useLocation();
    const usedPages = location.pathname;
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    useEffect(() => {
        setIsSidebarOpen(false);
    }, [usedPages]);

    const handleClickOutside = (event) => {
        if (sideNavRef.current && !sideNavRef.current.contains(event.target)) {
            setIsSidebarOpen(false);
        }
    };

    useEffect(() => {
        if (isSidebarOpen) {
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [isSidebarOpen]);

    return (
        <div className="flex w-full justify-between items-center p-2 md:p-4">
            <IoMenuSharp
                size={30}
                className="cursor-pointer lg:hidden"
                onClick={() => setIsSidebarOpen(true)}
            />
            <div
                className="flex items-center gap-4"
                title="Công TNHH Sản xuất và Kinh doanh Lâm sản Phú Thọ"
            >
                <Link className="flex gap-1.5 items-center" to="/">
                    <img src={Logo} alt="Logo" className="h-8" />
                    <img src={NameCompany} alt="NameCompany" className="h-7 md:h-10" />
                </Link>
                {/* <div className="flex flex-col items-center text-[#545454]">
                    <p
                        className="text-2xl font-bold leading-none tracking-wider"
                        style={{ fontFamily: "Teko" }}
                    >
                        PHUTHOWOOD
                    </p>
                    <p className="text-[10px]">BUILDING TRUST, GROWING TOGETHER</p>
                </div> */}
            </div>
            <nav className="flex gap-6 max-lg:hidden text-[#333]">
                <Link
                    to="/"
                    className={` ${
                        usedPages === "/"
                            ? "font-bold"
                            : "hover:text-green-600 hvr-underline-from-center"
                    }`}
                >
                    {t("home")}
                </Link>
                <Link
                    to="/products"
                    className={` ${
                        usedPages.includes("/products")
                            ? "font-bold"
                            : "hover:text-green-600 hvr-underline-from-center"
                    }`}
                >
                    {t("products")}
                </Link>
                {/* <Link
                    to="/news"
                    className={`${
                        usedPages.includes("/news")
                            ? "font-bold"
                            : "hover:text-green-600 hvr-underline-from-center"
                    }`}
                >
                    {t("news")}
                </Link> */}
                <Link
                    to="/contact"
                    className={` ${
                        usedPages.includes("/contact")
                            ? "font-bold"
                            : "hover:text-green-600 hvr-underline-from-center"
                    }`}
                >
                    {t("contact")}
                </Link>
                <Link
                    to="/about-us"
                    className={`${
                        usedPages.includes("/about-us")
                            ? "font-bold"
                            : "hover:text-green-600 hvr-underline-from-center"
                    }`}
                >
                    {t("about-us")}
                </Link>
            </nav>

            {isSidebarOpen && (
                <div className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-20" />
            )}
            {/* Sidebar for mobile */}
            <div
                ref={sideNavRef}
                className={`fixed top-0 left-0 h-full w-64 bg-white shadow-lg z-50 transform transition-transform duration-300 ${
                    isSidebarOpen ? "translate-x-0" : "-translate-x-full"
                } lg:hidden`}
            >
                <div className="flex justify-end h-20 p-2">
                    <MdClose
                        size={24}
                        className="text-gray-500 cursor-pointer"
                        onClick={() => setIsSidebarOpen(false)}
                    />
                </div>
                <nav className="flex flex-col gap-4 text-[#333]">
                    <Link
                        to="/"
                        className={`pl-4 h-10 ${
                            usedPages === "/"
                                ? "font-bold border-b-2 border-secondary text-secondary"
                                : "font-medium border-b border-gray-600"
                        }`}
                        // onClick={handleMenuClick}
                    >
                        {t("home")}
                    </Link>
                    <Link
                        to="/products"
                        className={`pl-4 h-10 ${
                            usedPages.includes("/products")
                                ? "font-bold border-b-2 border-secondary text-secondary"
                                : "font-medium border-b border-gray-600"
                        }`}
                        // onClick={handleMenuClick}
                    >
                        {t("products")}
                    </Link>
                    {/* <Link
                        to="/news"
                        className={`pl-4 h-10 ${
                            usedPages.includes("/news")
                                ? "font-bold border-b-2 border-secondary text-secondary"
                                : "font-medium border-b border-gray-600"
                        }`}
                        // onClick={handleMenuClick}
                    >
                        {t("news")}
                    </Link> */}
                    <Link
                        to="/contact"
                        className={`pl-4 h-10 ${
                            usedPages.includes("/contact")
                                ? "font-bold border-b-2 border-secondary text-secondary"
                                : "font-medium border-b border-gray-600"
                        }`}
                        // onClick={handleMenuClick}
                    >
                        {t("contact")}
                    </Link>
                    <Link
                        to="/about-us"
                        className={`pl-4 h-10 ${
                            usedPages.includes("/about-us")
                                ? "font-bold border-b-2 border-secondary text-secondary"
                                : "font-medium border-b border-gray-600"
                        }`}
                        // onClick={handleMenuClick}
                    >
                        {t("about-us")}
                    </Link>
                </nav>
            </div>
            <select
                value={i18n.language}
                // onChange={(e) => changeLanguage(e.target.value)}
                onChange={(e) => changeLanguage(e.target.value)}
                className="p-1 md:p-2 border rounded appearance-none cursor-pointer"
                style={{
                    fontFamily: "Segoe UI Emoji",
                }}
            >
                <option value="vi">🇻🇳 Tiếng Việt</option>
                <option value="en">🇬🇧 English</option>
            </select>
        </div>
    );
}
export default NavBar;
