import { useTranslation } from "react-i18next";
import styled from "styled-components";

const DetailSection = () => {
    const { t, i18n } = useTranslation();
    return (
        <DetailWrapper>
            <DetailHeader>{t("product-details")}</DetailHeader>
            <DetailContent>
                <Section>
                    <SectionTitle>
                        {i18n.language === "vi" ? "Giới thiệu ván ép cốt" : "What is Core Plywood?"}
                    </SectionTitle>{" "}
                    <p className="pl-4 text-gray-600 leading-relaxed">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {i18n.language === "vi" ? (
                            <>
                                <strong>Ván ép cốt</strong> là loại vật liệu xây dựng và nội thất
                                này được hình thành từ các lớp gỗ mỏng (veneer) xếp chồng lên nhau
                                theo các hướng khác nhau, sau đó được ép chặt bằng keo dưới áp lực
                                và nhiệt độ cao. Sự kết hợp này mang lại cho ván ép cốt độ bền vượt
                                trội và khả năng chịu lực tốt, đáp ứng nhu cầu sử dụng trong nhiều
                                lĩnh vực khác nhau.
                            </>
                        ) : (
                            <>
                                <strong>Core plywood</strong> is a type of construction and interior
                                material formed from thin layers of wood (veneer) stacked in
                                different directions, then pressed tightly with glue under high
                                pressure and temperature. This combination gives core plywood
                                outstanding durability and good bearing capacity, meeting the needs
                                of use in many different fields.
                            </>
                        )}
                    </p>
                </Section>

                <Section>
                    <SectionTitle>{t("product-information")}</SectionTitle>
                    <TableWrapper>
                        <Table>
                            <tbody>
                                <Tr>
                                    <TdLabel>{t("measurement")}</TdLabel>
                                    <TdContent>1220x2440mm, 910x1820mm,….</TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("popular-thickness")}</TdLabel>
                                    <TdContent>2.5 - 30mm</TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("tolerance")}</TdLabel>
                                    <TdContent>±0.3 - 0.5mm</TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("veneer-grade")}</TdLabel>
                                    <TdContent>
                                        {i18n.language === "vi"
                                            ? "Độn Bạch đàn, Keo"
                                            : "Grade Eucalyptus, Acacia"}
                                    </TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("application")}</TdLabel>
                                    <TdContent>
                                        {i18n.language === "vi"
                                            ? "làm hàng nội thất, đóng thùng,..."
                                            : "packing, furniture,.."}
                                    </TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("glue")}</TdLabel>
                                    <TdContent>E0, E1, E2</TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("face")}</TdLabel>
                                    <TdContent>
                                        {i18n.language === "vi" ? "Không mặt" : "No Face"}
                                    </TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("density")}</TdLabel>
                                    <TdContent>
                                        560 - 650 kgs/m<sup>3</sup>
                                    </TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("moisture")}</TdLabel>
                                    <TdContent>
                                        {" "}
                                        {i18n.language === "vi" ? "Dưới" : "Under"} 18%
                                    </TdContent>
                                </Tr>
                            </tbody>
                        </Table>
                    </TableWrapper>
                </Section>

                <Section>
                    <SectionTitle>
                        {i18n.language === "vi"
                            ? "Đặc điểm nổi bật của ván ép cốt"
                            : "Outstanding feature"}
                    </SectionTitle>
                    <SectionContent>
                        <ContentTitle>
                            {i18n.language === "vi" ? "Độ bền cao" : "High durability"}
                        </ContentTitle>
                        <p className="content-text">
                            {i18n.language === "vi"
                                ? "Ván ép cốt có khả năng chịu lực rất tốt nhờ vào việc sử dụng nhiều lớp gỗ xếp chồng lên nhau. Điều này cho phép sản phẩm chịu được tải trọng lớn mà không bị biến dạng hay nứt nẻ."
                                : "Core plywood has very good load-bearing capacity thanks to the use of many layers of wood stacked on top of each other. This allows the product to withstand large loads without deformation or cracking."}
                        </p>

                        <ContentTitle>
                            {i18n.language === "vi"
                                ? "Khả năng chống ẩm và mối mọt"
                                : "Resistance to moisture and termites"}
                        </ContentTitle>
                        <p className="content-text">
                            {i18n.language === "vi"
                                ? "Ván ép cốt thường được xử lý đặc biệt để chống lại sự tấn công của mối mọt và độ ẩm. Điều này giúp tăng cường độ bền của sản phẩm khi sử dụng trong các môi trường khắc nghiệt."
                                : "Core plywood is often specially treated to resist termite and moisture attack. This enhances the durability of the product when used in harsh environments."}
                        </p>

                        <ContentTitle>
                            {i18n.language === "vi" ? "Dễ gia công" : "Easy Processing Capacity"}
                        </ContentTitle>
                        <p className="content-text">
                            {i18n.language === "vi"
                                ? "Ván ép cốt dễ dàng được cắt, khoan và tạo hình theo nhu cầu, giúp thuận tiện trong việc thiết kế và thi công các sản phẩm nội thất cũng như các công trình xây dựng."
                                : "Core plywood can be easily cut, drilled, and shaped as needed, making it convenient for designing and constructing interior products as well as construction projects."}
                        </p>

                        <ContentTitle>
                            {i18n.language === "vi" ? "Độ ổn định cao" : "High stability"}
                        </ContentTitle>
                        <p className="content-text">
                            {i18n.language === "vi"
                                ? "Nhờ cấu trúc nhiều lớp gỗ ép, ván ép cốt ít bị cong vênh hay biến dạng khi gặp điều kiện thời tiết thay đổi, đảm bảo độ ổn định trong thời gian dài."
                                : "Thanks to its multi-layer plywood structure, core plywood is less likely to warp or deform when exposed to changing weather conditions, ensuring long-term stability."}
                        </p>
                    </SectionContent>
                </Section>

                <Section>
                    <SectionTitle>
                        {i18n.language === "vi"
                            ? "Ứng dụng thực tế của ván ép cốt"
                            : "Real application of Core Plywood"}
                    </SectionTitle>
                    <SectionContent>
                        <ContentTitle>
                            {i18n.language === "vi" ? "Trong xây dựng" : "Construction"}
                        </ContentTitle>
                        <p className="content-text">
                            {i18n.language === "vi"
                                ? "Ván ép cốt được sử dụng rộng rãi trong các công trình xây dựng, đặc biệt là trong các hệ thống cốp pha, ván khuôn và các kết cấu cần độ chắc chắn và bền bỉ."
                                : "Core plywood is used widely in building construction, especially formwork, and structures requiring stability and durability."}
                        </p>
                        <ContentTitle>
                            {i18n.language === "vi" ? "Trong sản xuất nội thất" : "Furniture"}
                        </ContentTitle>
                        <p className="content-text">
                            {i18n.language === "vi"
                                ? "Sản phẩm thường được sử dụng để làm bàn, ghế, tủ, kệ và nhiều vật dụng nội thất khác nhờ vào khả năng gia công dễ dàng và tính thẩm mỹ cao."
                                : "Core plywood is also usually used to manufacture tables, chairs, cabinets, shelves, and other furniture due to its easy processing capacity and high aesthetic."}
                        </p>
                        <ContentTitle>
                            {i18n.language === "vi" ? "Ứng dụng khác" : "Other application"}
                        </ContentTitle>
                        <p className="content-text">
                            {i18n.language === "vi"
                                ? "Ngoài ra, ván ép cốt còn được sử dụng để sản xuất ván sàn, ốp tường, trần nhà và nhiều ứng dụng nội thất trang trí khác."
                                : "Flooring, wall cladding, ceiling panel,..."}
                        </p>
                    </SectionContent>
                </Section>
            </DetailContent>
        </DetailWrapper>
    );
};

export default DetailSection;

const DetailWrapper = styled.div`
    position: relative;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    padding: 24px;
    margin: 32px 0;
`;

const DetailHeader = styled.div`
    position: absolute;
    top: -20px;
    left: 16px;
    background-color: white;
    padding: 0 12px;
    font-size: 1.5rem;
    font-weight: 600;
    // text-transform: uppercase;
    color: #4a5568;
`;

const DetailContent = styled.div`
    margin-top: 16px;
`;

const Section = styled.section`
    margin-bottom: 24px;
`;

const SectionTitle = styled.h3`
    color: #2f855a;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 16px;
`;
const TableWrapper = styled.div`
    // width: 100%;
    margin: 0 -16px;
    // overflow-x: auto;

    @media (min-width: 768px) {
        margin: 0 auto;
    }
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;

    @media (min-width: 768px) {
        min-width: 500px;
        max-width: 750px;
        margin-left: auto;
        margin-right: auto;
    }
`;

const Tr = styled.tr`
    &:hover {
        background-color: #f8f9fa;
    }
`;

const TdLabel = styled.td`
    font-weight: bold;
    width: 30%;
    min-width: 120px;
    word-wrap: break-word;
    white-space: normal;
    // padding: 8px;

    @media (min-width: 768px) {
        width: 25%;
        min-width: 150px;
    }
`;

const TdContent = styled.td`
    width: 65%;
    word-wrap: break-word;
    white-space: normal;
    padding: 8px;

    @media (min-width: 768px) {
        width: 75%;
    }
`;

const SectionContent = styled.div`
    .content-text {
        color: #4b5563; // equivalent to text-gray-600
        margin-left: 1rem; // equivalent to ml-4
        margin-bottom: 1rem; // equivalent to mb-4
        line-height: 1.625; // equivalent to leading-relaxed
    }

    .content-list {
        list-style-type: disc;
        list-style-position: outside;
        margin-left: 1rem;
        margin-bottom: 1rem;
        color: #4b5563;

        li {
            margin-bottom: 0.5rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
`;

const ContentTitle = styled.h4`
    font-weight: 600;
    color: #374151;
    margin-bottom: 5px;
    margin-left: 10px;
`;
