import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ManufacturerInfo from "../ManufacturerInfo";
import StatusProduct from "../StatusProduct";

const InforSection = () => {
    const { t, i18n } = useTranslation();
    return (
        <InfoSection>
            <h2 className="text-2xl font-bold mb-4">
                {i18n.language === "vi" ? "Ván ép cốt" : "Core Plywood"}
            </h2>
            <p className="mb-4">
                {i18n.language === "vi" ? (
                    <>
                        <strong>Ván ép cốt</strong> - Chất lượng, bền bỉ theo thời gian, giá cả cạnh tranh
                    </>
                ) : (
                    <>
                        <strong>Core Plywood</strong> - Quality, Durable Over Time, Competitive Pricing
                    </>
                )}
            </p>
            <SpecsTable>
                <tbody>
                    <tr>
                        <SpecTdLabel>{t("measurement")}</SpecTdLabel>
                        <td>1220x2440mm, 910x1820mm,….</td>
                    </tr>
                    <tr>
                        <SpecTdLabel>{t("popular-thickness")}</SpecTdLabel>
                        <td>2.5 - 30mm</td>
                    </tr>
                    <tr>
                        <SpecTdLabel>{t("tolerance")}</SpecTdLabel>
                        <td>±0.3 - 0.5mm</td>
                    </tr>
                    <tr>
                        <SpecTdLabel>{t("species")}</SpecTdLabel>
                        <td>
                            {i18n.language === "vi" 
                                ? "Độn Bạch đàn, Keo" 
                                : "Eucalyptus, Acacia"}
                        </td>
                    </tr>
                    <tr>
                        <SpecTdLabel>{t("face")}</SpecTdLabel>
                        <td>
                            {i18n.language === "vi" 
                                ? "Không mặt" 
                                : "No Face"}
                        </td>
                    </tr>
                    <tr>
                        <SpecTdLabel>{t("glue")}</SpecTdLabel>
                        <td>E0, E1, E2</td>
                    </tr>
                    <tr>
                        <SpecTdLabel>{t("status")}</SpecTdLabel>
                        <td>
                            <StatusProduct />
                        </td>
                    </tr>
                    <tr>
                        <SpecTdLabel>{t("manufacturer")}</SpecTdLabel>
                        <td>
                            <ManufacturerInfo />
                        </td>
                    </tr>
                </tbody>
            </SpecsTable>
        </InfoSection>
    );
};

export default InforSection;

const InfoSection = styled.div`
    flex: 1;
    max-width: 600px;
    width: 100%;
`;

const SpecsTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-top: 16px;

    td {
        border: 1px solid #e1e1e1;
        color: #1c1c1c;
    }
`;

const SpecTdLabel = styled.td`
    font-weight: 600;
    width: 30%;
    min-width: 200px;
    @media (max-width: 768px) {
        width: 40%;
        min-width: 150px;
    }
    background-color: #f8f9fa;
`;