import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Magnifier from "react-magnifier";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Footer from "../components/Footer";
import products, { getProductName } from "../data/productList";

const SimilarProducts = ({ currentProductId, products }) => {
    const { i18n } = useTranslation();
    const otherProducts = products.filter((product) => product.id !== currentProductId);

    return (
        <SimilarProductsWrapper>
            <h2 className="title">
                {i18n.language === "vi" ? "Sản phẩm tương tự" : "Similar Products"}
            </h2>
            <SwiperContainer>
                <NavButton className="prev-button">
                    <IoIosArrowBack />
                </NavButton>
                <NavButton className="next-button">
                    <IoIosArrowForward />
                </NavButton>
                <Swiper
                    modules={[Navigation]}
                    navigation={{
                        prevEl: ".prev-button",
                        nextEl: ".next-button",
                    }}
                    spaceBetween={8}
                    slidesPerView={2}
                    breakpoints={{
                        640: {
                            slidesPerView: 3,
                            spaceBetween: 12,
                        },
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 16,
                        },
                        1024: {
                            slidesPerView: 4,
                            spaceBetween: 24,
                        },
                    }}
                >
                    {otherProducts.map((product) => (
                        <SwiperSlide key={product.id}>
                            <ProductCard
                                to={`/products/${product.id}`}
                                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
                            >
                                <ImageWrapper>
                                    <img
                                        src={product.images[0]}
                                        alt={getProductName(product.name, i18n.language)}
                                    />
                                </ImageWrapper>
                                <ProductName>
                                    {getProductName(product.name, i18n.language)}
                                </ProductName>
                            </ProductCard>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </SwiperContainer>
        </SimilarProductsWrapper>
    );
};

const ProductDetails = () => {
    const { id } = useParams();
    const { i18n } = useTranslation();
    const [product, setProduct] = useState();
    const [selectedImage, setSelectedImage] = useState("");
    const [lensSize, setLensSize] = useState(200);

    useEffect(() => {
        const productDetails = products.find((product) => product.id === id);
        setProduct(productDetails);
        setSelectedImage(productDetails?.images[0]);
    }, [id]);

    const handleWheel = (e) => {
        setLensSize((prevSize) => {
            const newSize = prevSize + (e.deltaY > 0 ? -20 : 20);
            return Math.max(150, Math.min(300, newSize));
        });
    };

    const InfoSectionComponent = product?.inforSection;
    const DetailSectionComponent = product?.detailSection;

    return (
        <div className="w-full flex flex-col items-center">
            <MainContainer>
                <ContentWrapper>
                    <ImageSection>
                        <MainImageWrapper onWheel={handleWheel}>
                            <Magnifier
                                src={selectedImage}
                                zoomFactor={1}
                                mgWidth={lensSize}
                                mgHeight={lensSize}
                                mgBorderWidth={1}
                                mgShape="square"
                                alt="Product view"
                                className="magnifier-image"
                            />
                        </MainImageWrapper>
                        <ThumbnailContainer>
                            {product?.images.map((image, index) => (
                                <Thumbnail
                                    key={index}
                                    src={image}
                                    alt={`${getProductName(product.name, i18n.language)}_${index}`}
                                    onClick={() => setSelectedImage(image)}
                                    $isSelected={image === selectedImage}
                                />
                            ))}
                        </ThumbnailContainer>
                    </ImageSection>
                    {InfoSectionComponent && <InfoSectionComponent />}
                </ContentWrapper>

                {DetailSectionComponent && <DetailSectionComponent />}
                <SimilarProducts currentProductId={id} products={products} />
            </MainContainer>
            <Footer />
        </div>
    );
};

export default ProductDetails;

const MainContainer = styled.div`
    width: 100%;
    max-width: 1200px;
    padding: 0 16px;
    margin: 0 auto;
`;

const ContentWrapper = styled.div`
    display: flex;
    gap: 32px;
    margin: 24px 0;

    @media (max-width: 1024px) {
        flex-direction: column;
        align-items: center;
    }
`;

const ImageSection = styled.div`
    flex: 1;
    max-width: 720px;
    width: 100%;
`;

const MainImageWrapper = styled.div`
    width: 100%;
    margin-bottom: 20px;

    .magnifier-image {
        width: 100%;
        height: auto;
        object-fit: cover;
        aspect-ratio: 16/9;
    }
`;

const ThumbnailContainer = styled.div`
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    justify-content: center;
`;

const Thumbnail = styled.img`
    width: 100px;
    height: 60px;
    object-fit: cover;
    cursor: pointer;
    border: 2px solid ${(props) => (props.$isSelected ? "#4a90e2" : "transparent")};
    transition: border-color 0.2s;

    &:hover {
        border-color: #4a90e2;
    }

    @media (min-width: 768px) {
        width: 200px;
        height: 120px;
    }

    @media (min-width: 1024px) {
        width: 150px;
        height: 90px;
    }
`;

const SimilarProductsWrapper = styled.div`
    margin: 2rem 0;

    .title {
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 1rem;
        color: #333;
    }
`;

const SwiperContainer = styled.div`
    position: relative;

    .swiper {
        padding: 0 2rem;
    }
`;

const NavButton = styled.button`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    width: 40px;
    height: 40px;
    border: none;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
        background-color: #f0f0f0;
    }

    svg {
        width: 20px;
        height: 20px;
        color: #333;
    }

    &.prev-button {
        left: -20px;
    }

    &.next-button {
        right: -20px;
    }
`;

const ProductCard = styled(Link)`
    display: block;
    text-decoration: none;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    transition: box-shadow 0.3s;

    &:hover {
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
`;

const ImageWrapper = styled.div`
    position: relative;
    padding-top: 56.25%;
    overflow: hidden;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const ProductName = styled.h3`
    padding: 0.4rem;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    @media (min-width: 768px) {
        padding: 1rem;
    }
    font-size: 1rem;
    font-weight: 500;
    color: #333;
`;
