import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";

const DetailSection = () => {
    const { t, i18n } = useTranslation();
    return (
        <DetailWrapper>
            <DetailHeader>{t("product-details")}</DetailHeader>
            <DetailContent>
                <Section>
                    <SectionTitle>
                        {i18n.language === "vi"
                            ? "Giới thiệu ván ép bao bì"
                            : "What is Packing Plywood?"}
                    </SectionTitle>
                    <p className="pl-4 text-gray-600 leading-relaxed">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {i18n.language === "vi" ? (
                            <>
                                <strong>Ván ép bao bì</strong> là loại vật liệu chuyên dụng, được
                                tạo ra từ nhiều lớp gỗ mỏng dán ép lại với nhau dưới áp lực cao. Nhờ
                                quy trình sản xuất hiện đại và chất liệu chất lượng, ván ép bao bì
                                cung cấp độ bền vững và khả năng chịu lực vượt trội, phù hợp cho
                                nhiều ứng dụng trong ngành công nghiệp đóng gói và vận chuyển.
                            </>
                        ) : (
                            <>
                                <strong>Packaging plywood</strong> is a specialized material made
                                from several thin layers of wood bonded together under high
                                pressure. Thanks to modern manufacturing processes and quality
                                materials, packaging plywood provides excellent durability and
                                superior load-bearing capacity, making it suitable for various
                                applications in the packaging and transportation industry.
                            </>
                        )}
                    </p>
                </Section>

                <Section>
                    <SectionTitle>{t("product-information")}</SectionTitle>
                    <TableWrapper>
                        <Table>
                            <tbody>
                                <Tr>
                                    <TdLabel>{t("measurement")}</TdLabel>
                                    <TdContent>1220x2440mm, 910x1820mm,….</TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("popular-thickness")}</TdLabel>
                                    <TdContent>
                                        <p>7mm - 4 {t("layer")}</p>
                                        <p>8mm - 5 {t("layer")}</p>
                                        <p>11mm - 7 {t("layer")}</p>
                                        <p>14mm - 9 {t("layer")}</p>
                                        <p>17mm - 11 {t("layer")}</p>
                                    </TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("tolerance")}</TdLabel>
                                    <TdContent>
                                        {i18n.language === "vi"
                                            ? "±0.3mm /Dương ly"
                                            : "±0.3mm /Positive Millimeters"}
                                    </TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("veneer-grade")}</TdLabel>
                                    <TdContent>
                                        {i18n.language === "vi"
                                            ? "Độn A/B/C tùy theo mục đích sử dụng"
                                            : "Grade A/B/C, depends on purposes"}
                                    </TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("application")}</TdLabel>
                                    <TdContent>
                                        {i18n.language === "vi"
                                            ? "làm thùng đóng hàng, đóng hộp, pallet,..."
                                            : "packing, pallet,.."}
                                    </TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("glue")}</TdLabel>
                                    <TdContent>urea formaldyhe</TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("formaldehyde-emission")}</TdLabel>
                                    <TdContent>E0/E1/E2</TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("face")}</TdLabel>
                                    <TdContent>
                                        <p>
                                            {i18n.language === "vi" ? "Mặt trước" : "Front"}:
                                            Bintangor 0.13 - 0.15mm
                                        </p>
                                        <p>
                                            {i18n.language === "vi"
                                                ? "Mặt sau: Bồ đề"
                                                : "Back: Styrax"}{" "}
                                            0.35 - 0.4mm
                                        </p>
                                    </TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("color")}</TdLabel>
                                    <TdContent>
                                        <p>
                                            {i18n.language === "vi"
                                                ? "1 mặt đỏ, 1 mặt trắng"
                                                : "1 red face, 1 white face"}
                                        </p>
                                    </TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("species")}</TdLabel>
                                    <TdContent>
                                        <p>
                                            {i18n.language === "vi"
                                                ? "Keo, Bồ đề"
                                                : "Acacia, Styrax"}
                                        </p>
                                    </TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("technology-information")}</TdLabel>
                                    <TdContent>
                                        <p>
                                            {i18n.language === "vi"
                                                ? "1 lần ép nóng"
                                                : "1 hot pressing time"}
                                        </p>
                                        <p>{i18n.language === "vi" ? "Không chà" : "No sanding"}</p>
                                    </TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("density")}</TdLabel>
                                    <TdContent>
                                        550 - 580 kgs/m<sup>3</sup>
                                    </TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("moisture")}</TdLabel>
                                    <TdContent>
                                        {i18n.language === "vi" ? "Dưới" : "Under"} 15%
                                    </TdContent>
                                </Tr>
                            </tbody>
                        </Table>
                    </TableWrapper>
                </Section>

                <Section>
                    <SectionTitle>
                        {i18n.language === "vi"
                            ? "Ứng dụng thực tế của Ván ép Bao bì"
                            : "Real application of Packing Plywood"}
                    </SectionTitle>
                    <SectionContent>
                        <ContentTitle>
                            {i18n.language === "vi" ? "Đóng gói hàng hóa" : "Goods Packaging"}
                        </ContentTitle>
                        <p className="content-text">
                            {i18n.language === "vi"
                                ? "Ván ép bao bì được sử dụng rộng rãi trong việc đóng thùng, kiện hàng, lót nền trong container, làm khung sofa, làm bobbin, và bảo vệ các loại máy móc, thiết bị công nghiệp."
                                : "Packing plywood is widely used in packaging boxes, pallets, lining containers, making sofa frames, making bobbin, and protecting industrial machinery and equipment."}
                        </p>

                        <ContentTitle>
                            {i18n.language === "vi" ? "Trong xuất khẩu" : "In Exporting"}
                        </ContentTitle>
                        <p className="content-text">
                            {i18n.language === "vi"
                                ? "Với tính năng bền bỉ, ván ép bao bì giúp bảo quản sản phẩm an toàn trong quá trình vận chuyển đường dài, đặc biệt là trong các lô hàng xuất khẩu."
                                : " With its durable features, packaging plywood helps to keep products safe during long-distance transportation, especially in export shipments."}
                        </p>

                        <ContentTitle>
                            {i18n.language === "vi"
                                ? "Bảo vệ sản phẩm dễ vỡ"
                                : "Protecting Fragile Products"}
                        </ContentTitle>
                        <p className="content-text">
                            {i18n.language === "vi"
                                ? "Đối với những sản phẩm nhạy cảm như đồ gốm sứ và thiết bị điện tử, ván ép bao bì tăng cường khả năng chống va đập."
                                : "Packaging plywood provides enhanced impact resistance for sensitive products such as ceramics and electronics."}
                        </p>

                        <Conclusion>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {i18n.language === "vi" ? (
                                <>
                                    Với các ưu điểm nổi bật về chất lượng và ứng dụng,{" "}
                                    <strong>ván ép bao bì</strong> đang trở thành lựa chọn hàng đầu
                                    cho nhiều doanh nghiệp trong lĩnh vực đóng gói và vận chuyển.
                                    Hãy chọn <strong>ván ép bao bì</strong> của{" "}
                                    <Link
                                        className="text-blue-600 underline font-semibold"
                                        to={"/"}
                                    >
                                        Lâm sản Phú Thọ
                                    </Link>{" "}
                                    để bảo vệ sản phẩm của bạn một cách tối ưu và an toàn nhất!
                                </>
                            ) : (
                                <>
                                    With outstanding advantages in quality and application,{" "}
                                    <strong>packing plywood</strong> is becoming the top choice for
                                    many businesses in packaging and transportation. Choose{" "}
                                    <strong>packing plywood</strong> of{" "}
                                    <Link
                                        className="text-blue-600 underline font-semibold"
                                        to={"/"}
                                    >
                                        PhuTho Wood
                                    </Link>{" "}
                                    to protect your products most optimally and safely!
                                </>
                            )}
                        </Conclusion>
                    </SectionContent>
                </Section>
            </DetailContent>
        </DetailWrapper>
    );
};

export default DetailSection;

const DetailWrapper = styled.div`
    position: relative;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    padding: 24px;
    margin: 32px 0;
`;

const DetailHeader = styled.div`
    position: absolute;
    top: -20px;
    left: 16px;
    background-color: white;
    padding: 0 12px;
    font-size: 1.5rem;
    font-weight: 600;
    // text-transform: uppercase;
    color: #4a5568;
`;

const DetailContent = styled.div`
    margin-top: 16px;
`;

const Section = styled.section`
    margin-bottom: 24px;
`;

const SectionTitle = styled.h3`
    color: #2f855a;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 16px;
`;

const TableWrapper = styled.div`
    // width: 100%;
    margin: 0 -16px;
    // overflow-x: auto;

    @media (min-width: 768px) {
        margin: 0 auto;
    }
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;

    @media (min-width: 768px) {
        min-width: 500px;
        max-width: 750px;
        margin-left: auto;
        margin-right: auto;
    }
`;

const Tr = styled.tr`
    &:hover {
        background-color: #f8f9fa;
    }
`;

const TdLabel = styled.td`
    font-weight: bold;
    width: 30%;
    min-width: 120px;
    word-wrap: break-word;
    white-space: normal;
    // padding: 8px;

    @media (min-width: 768px) {
        width: 25%;
        min-width: 150px;
    }
`;

const TdContent = styled.td`
    width: 65%;
    word-wrap: break-word;
    white-space: normal;
    padding: 8px;

    @media (min-width: 768px) {
        width: 75%;
    }
`;

const SectionContent = styled.div`
    .content-text {
        color: #4b5563; // equivalent to text-gray-600
        margin-left: 1rem; // equivalent to ml-4
        margin-bottom: 1rem; // equivalent to mb-4
        line-height: 1.625; // equivalent to leading-relaxed
    }

    .content-list {
        list-style-type: disc;
        list-style-position: outside;
        margin-left: 1rem;
        margin-bottom: 1rem;
        color: #4b5563;

        li {
            margin-bottom: 0.5rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
`;

const ContentTitle = styled.h4`
    font-weight: 600;
    color: #374151;
    margin-bottom: 5px;
    margin-left: 10px;
`;
const Conclusion = styled.p`
    position: relative;
    background: linear-gradient(to right, #f0f8ff, #e6f2ff);
    padding: 20px;
    border-radius: 8px;
    border-left: 4px solid #4299e1;
    font-size: 18px;
    font-style: italic;
    color: #2c5282;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    }

    &::before {
        content: "✓";
        position: absolute;
        top: 15px;
        left: -30px;
        background: #4299e1;
        color: white;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
    }
`;
