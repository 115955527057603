import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import vi from './locales/vi.json';

const defaultLanguage = localStorage.getItem('lng') || 'vi';
i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    vi: {
      translation: vi,
    },
  },
  lng: defaultLanguage, // Ngôn ngữ mặc định
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // React đã tự động bảo vệ khỏi XSS
  },
});

export const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem('lng', language); // Lưu ngôn ngữ mới vào localStorage
  };

export default i18n;
