import "hover.css/css/hover.css";
import { Toaster } from "react-hot-toast";
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import NavBar from "./components/NavBar";
import RightSidebar from "./components/RightSlidebar";
import ScrollToTop from "./components/ScrollToTop";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import ProductDetails from "./pages/ProductDetails";
import Products from "./pages/Products";

function App() {
    // const { i18n } = useTranslation();
    // const [language, setLanguage] = useState(i18n.language);
    // const changeLanguage = (lang) => {
    //     setLanguage(lang);
    //     i18n.changeLanguage(lang);
    // };

    return (
        <Router>
            <ScrollToTop />
            <Toaster toastOptions={{ duration: 3000 }} />
            <NavBar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/products" element={<Products />} />
                <Route path="/products/:id" element={<ProductDetails />} />
                {/* <Route path="/news" element={<News />} /> */}
                <Route path="/contact" element={<Contact />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            <RightSidebar />
        </Router>
    );
}

export default App;
