import { motion } from "framer-motion";
import React, { lazy, Suspense, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsSearch } from "react-icons/bs";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import products, { getProductName } from "../data/productList";

// Lazy load Footer component
const Footer = lazy(() => import("../components/Footer"));

// Optimize animation performance with useMemo
const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.05, // Reduced from 0.1 for smoother animation
        },
    },
};

const productVariants = {
    hidden: {
        opacity: 0,
        y: 10, // Reduced from 20 for subtler animation
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.3, // Reduced from 0.5 for faster animation
        },
    },
};

// Optimized TypingEffect component with better performance
function TypingEffect() {
    const { i18n } = useTranslation();
    const [displayText, setDisplayText] = useState("");
    const fullText =
        i18n.language === "en"
            ? "Explore the reputable and high-quality product lines of PhuTho Wood"
            : "Khám phá các dòng sản phẩm uy tín và chất lượng cao của Lâm sản Phú Thọ";
    const typingSpeed = 50;

    useEffect(() => {
        // Reset displayText khi i18n.language thay đổi
        setDisplayText("");
    }, [i18n.language]);

    useEffect(() => {
        let isMounted = true;

        if (displayText.length < fullText.length) {
            const timer = setTimeout(() => {
                if (isMounted) {
                    setDisplayText((prev) => fullText.slice(0, prev.length + 1));
                }
            }, typingSpeed);

            return () => {
                isMounted = false;
                clearTimeout(timer);
            };
        }
    }, [displayText, fullText]);

    return (
        <TypingContainer>
            <TextWrapper>
                {displayText}
                {displayText.length < fullText.length && <Cursor />}
            </TextWrapper>
        </TypingContainer>
    );
}

// Optimized ProductCard component
const ProductCard = React.memo(({ product }) => {
    const { t, i18n } = useTranslation();
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    return (
        <motion.div
            ref={ref}
            variants={productVariants}
            whileHover={{ y: -5, transition: { duration: 0.2 } }}
            className="group"
            style={{
                opacity: inView ? 1 : 0,
                transform: `translateY(${inView ? 0 : 10}px)`,
                transition: "opacity 0.3s, transform 0.3s",
            }}
        >
            <Link
                to={`/products/${product.id}`}
                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
                <div className="bg-white rounded-xl overflow-hidden shadow-sm hover:shadow-xl transition-all duration-300">
                    <div className="relative aspect-video overflow-hidden">
                        <LazyImage
                            src={product.images[0]}
                            alt={getProductName(product.name, i18n.language)}
                            className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
                        />
                        <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                            <div className="absolute bottom-0 left-0 right-0 p-4 transform translate-y-full group-hover:translate-y-0 transition-transform duration-300">
                                <span className="inline-block px-3 py-1 bg-white/90 backdrop-blur-sm text-sm text-blue-600 rounded-full">
                                    {t("view_details")}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="mx-auto px-1 py-1 md:px-4 md:py-2">
                        <h3 className="text-lg whitespace-nowrap text-center font-semibold text-gray-800 mb-1 lg:mb-2 group-hover:text-blue-600 transition-colors duration-300">
                            {getProductName(product.name, i18n.language)}
                        </h3>
                        <div className="h-1 w-0 group-hover:w-full bg-blue-500 transition-all duration-300"></div>
                    </div>
                </div>
            </Link>
        </motion.div>
    );
});

// Lazy loading image component with blur effect
const LazyImage = ({ src, alt, className }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0,
    });

    return (
        <div ref={ref} className="relative">
            {inView && (
                <>
                    <img
                        src={src}
                        alt={alt}
                        className={`${className} ${isLoaded ? "opacity-100" : "opacity-0"}`}
                        style={{ transition: "opacity 0.3s" }}
                        onLoad={() => setIsLoaded(true)}
                        loading="lazy"
                    />
                    {!isLoaded && <div className="absolute inset-0 bg-gray-200 animate-pulse" />}
                </>
            )}
        </div>
    );
};

const Products = () => {
    const { i18n } = useTranslation();
    const [searchTerm, setSearchTerm] = useState("");
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
    const [filteredProducts, setFilteredProducts] = useState(products);

    // Debounce search input
    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 300);

        return () => clearTimeout(timer);
    }, [searchTerm]);

    // Memoized filter function
    const filterProducts = useCallback(() => {
        return products.filter((product) =>
            getProductName(product.name, i18n.language)
                .toLowerCase()
                .includes(debouncedSearchTerm.toLowerCase())
        );
    }, [debouncedSearchTerm, i18n.language]);

    useEffect(() => {
        setFilteredProducts(filterProducts());
    }, [debouncedSearchTerm, filterProducts]);

    return (
        <div className="min-h-screen bg-gray-50">
            <section className="relative bg-gradient-to-r from-green-400 to-green-500 text-white py-16 md:py-24">
                <div className="absolute inset-0 overflow-hidden">
                    <div className="absolute inset-0 bg-black opacity-30"></div>
                    <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-40"></div>
                </div>

                <div className="relative container mx-auto px-4">
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6 }}
                        className="text-center"
                    >
                        <h1 className="text-3xl md:text-5xl font-bold mb-6">
                            {i18n.language === "vi" ? "Sản phẩm của chúng tôi" : "Our Products"}
                        </h1>
                        <TypingEffect />
                    </motion.div>

                    <div className="max-w-xl mx-auto relative">
                        <input
                            type="text"
                            placeholder={
                                i18n.language === "vi" ? "Tìm kiếm sản phẩm" : "Search product"
                            }
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="w-full px-6 py-4 rounded-full text-gray-800 bg-white/90 backdrop-blur-sm shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-500 pl-14"
                        />
                        <BsSearch className="absolute left-5 top-1/2 -translate-y-1/2 text-gray-500 w-5 h-5" />
                    </div>
                </div>
            </section>

            <section className="container mx-auto px-1.5 py-8 xl:px-10 md:py-12">
                <motion.div
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                    className="grid grid-cols-2 sm:grid-cols-3 gap-4 md:gap-6 lg:gap-8"
                >
                    {filteredProducts.map((product) => (
                        <div key={product.id} className="max-w-[400px] w-full mx-auto">
                            <ProductCard product={product} />
                        </div>
                    ))}
                </motion.div>

                {filteredProducts.length === 0 && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        className="text-center py-12"
                    >
                        <p className="text-xl text-gray-600">
                            {i18n.language === "vi"
                                ? "Không tìm thấy sản phẩm phù hợp với từ khóa "
                                : "Not found products with keyword "}
                            "{searchTerm}"
                        </p>
                    </motion.div>
                )}
            </section>

            <Suspense fallback={<div>Loading...</div>}>
                <Footer />
            </Suspense>
        </div>
    );
};

export default Products;

const cursorBlink = keyframes`
    0%, 75% {
        opacity: 1;
    }
    76%, 100% {
        opacity: 0;
    }
`;

const TypingContainer = styled.div`
    max-width: 45rem;
    margin: 0 auto 2rem auto;
    text-align: center;

    @media (max-width: 768px) {
        padding: 0 1rem;
    }
`;

const TextWrapper = styled.p`
    display: inline;
    position: relative;
    font-size: 1.125rem;
    line-height: 1.75rem;
    opacity: 0.9;
    white-space: pre-wrap;
    word-break: break-word;

    @media (min-width: 768px) {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
`;

const Cursor = styled.span`
    display: inline-block;
    width: 2px;
    height: 1.2em;
    background-color: currentColor;
    margin-left: 2px;
    animation: ${cursorBlink} 1.1s infinite;
    vertical-align: middle;
`;
