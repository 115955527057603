import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ManufacturerInfo from "../ManufacturerInfo";
import StatusProduct from "../StatusProduct";

const InforSection = () => {
    const { t, i18n } = useTranslation();
    return (
        <InfoSection>
            <h2 className="text-2xl font-bold mb-4">
                {i18n.language === "vi" ? "Ván ép bao bì" : "Packing Plywood"}
            </h2>
            <p className="mb-4">
                {i18n.language === "vi" ? (
                    <>
                        <strong>Ván ép Bao bì</strong> - Giải pháp bảo vệ sản phẩm hiệu quả và bền
                        bỉ
                    </>
                ) : (
                    <>
                        <strong>Packing Plywood</strong> - Solution for product quality and
                        durability
                    </>
                )}
            </p>
            <SpecsTable>
                <tbody>
                    <tr>
                        <SpecTdLabel>{t("measurement")}</SpecTdLabel>
                        <td>1220x2440mm, 910x1820mm,….</td>
                    </tr>
                    <tr>
                        <SpecTdLabel>{t("tolerance")}</SpecTdLabel>
                        <td>
                            {i18n.language === "vi"
                                ? "±0.3mm /Dương ly"
                                : "±0.3mm /Positive Millimeters"}
                        </td>
                    </tr>
                    <tr>
                        <SpecTdLabel>{t("veneer-grade")}</SpecTdLabel>
                        <td>
                            {i18n.language === "vi"
                                ? "Độn A/B/C tùy theo mục đích sử dụng"
                                : "A/B/C, depends on purposes"}
                        </td>
                    </tr>
                    <tr>
                        <SpecTdLabel>{t("face")}</SpecTdLabel>
                        <td>
                            {i18n.language === "vi"
                                ? "1 mặt đỏ, 1 mặt trắng"
                                : "1 red face, 1 white face"}
                        </td>
                    </tr>
                    <tr>
                        <SpecTdLabel>{t("density")}</SpecTdLabel>
                        <td>
                            550 - 580 kgs/m<sup>3</sup>
                        </td>
                    </tr>
                    <tr>
                        <SpecTdLabel>{t("moisture")}</SpecTdLabel>
                        <td>{i18n.language === "vi" ? "Dưới" : "Under"} 15%</td>
                    </tr>
                    <tr>
                        <SpecTdLabel>{t("application")}</SpecTdLabel>
                        <td>
                            {i18n.language === "vi"
                                ? "làm thùng đóng hàng, đóng hộp, pallet,..."
                                : "packing, pallet,.."}
                        </td>
                    </tr>
                    <tr>
                        <SpecTdLabel>{t("status")}</SpecTdLabel>
                        <td>
                            <StatusProduct />
                        </td>
                    </tr>
                    <tr>
                        <SpecTdLabel>{t("manufacturer")}</SpecTdLabel>
                        <td>
                            <ManufacturerInfo />
                        </td>
                    </tr>
                </tbody>
            </SpecsTable>
        </InfoSection>
    );
};

export default InforSection;

const InfoSection = styled.div`
    flex: 1;
    max-width: 600px;
    width: 100%;
`;

const SpecsTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-top: 16px;

    td {
        // padding: 12px;
        border: 1px solid #e1e1e1;
        color: #1c1c1c;
    }
`;

const SpecTdLabel = styled.td`
    font-weight: 600;
    width: 30%;
    min-width: 200px;
    @media (max-width: 768px) {
        width: 40%;
        min-width: 150px;
    }
    background-color: #f8f9fa;
`;
