import DetailSectionVEBB from "./products/van-ep-bao-bi/DetailSection";
import InforSectionVEBB from "./products/van-ep-bao-bi/InforSection";
import DetailSectionVEB from "./products/van-ep-birch/DetailSection";
import InforSectionVEB from "./products/van-ep-birch/InforSection";
import DetailSectionVECD from "./products/van-ep-cha-dan/DetailSection";
import InforSectionVECD from "./products/van-ep-cha-dan/InforSection";
import DetailSectionVEC from "./products/van-ep-cot/DetailSection";
import InforSectionVEC from "./products/van-ep-cot/InforSection";
import DetailSectionVELVL from "./products/van-ep-lvl/DetailSection";
import InforSectionVELVL from "./products/van-ep-lvl/InforSection";
import DetailSectionVEPP from "./products/van-ep-phu-phim/DetailSection";
import InforSectionVEPP from "./products/van-ep-phu-phim/InforSection";

const products = [
    {
        id: "van-ep-phu-phim",
        name: {
            vi: "Ván ép phủ phim",
            en: "Film Faced Plywood",
        },
        images: [
            require("../assets/images/van_ep_phu_phim/1.png"),
            require("../assets/images/van_ep_phu_phim/2.png"),
            require("../assets/images/van_ep_phu_phim/3.png"),
        ],
        inforSection: InforSectionVEPP,
        detailSection: DetailSectionVEPP,
    },
    {
        id: "van-ep-cha-dan",
        name: {
            vi: "Ván ép chà dán - Tiêu chuẩn",
            en: "Commercial Plywood",
        },
        images: [
            require("../assets/images/van_ep_cha_dan/1.png"),
            require("../assets/images/van_ep_cha_dan/2.png"),
            require("../assets/images/van_ep_cha_dan/3.png"),
        ],
        inforSection: InforSectionVECD,
        detailSection: DetailSectionVECD,
    },
    {
        id: "van-ep-bao-bi",
        name: {
            vi: "Ván ép bao bì",
            en: "Packing Plywood",
        },
        images: [
            require("../assets/images/van_ep_bao_bi/1.png"),
            require("../assets/images/van_ep_bao_bi/2.png"),
            require("../assets/images/van_ep_bao_bi/3.png"),
        ],
        inforSection: InforSectionVEBB,
        detailSection: DetailSectionVEBB,
    },
    {
        id: "van-ep-lvl",
        name: {
            vi: "Ván ép LVL",
            en: "LVL Plywood",
        },
        images: [
            require("../assets/images/van_ep_lvl/1.png"),
            require("../assets/images/van_ep_lvl/2.png"),
            require("../assets/images/van_ep_lvl/3.png"),
        ],
        inforSection: InforSectionVELVL,
        detailSection: DetailSectionVELVL,
    },
    {
        id: "van-ep-cot",
        name: {
            vi: "Ván ép cốt",
            en: "Core Plywood",
        },
        images: [
            require("../assets/images/van_ep_cot/1.png"),
            require("../assets/images/van_ep_cot/2.png"),
            require("../assets/images/van_ep_cot/3.png"),
        ],
        inforSection: InforSectionVEC,
        detailSection: DetailSectionVEC,
    },
    {
        id: "van-ep-birch",
        name: {
            vi: "Ván ép Birch",
            en: "Birch Plywood",
        },
        images: [
            require("../assets/images/van_ep_birch/1.png"),
            require("../assets/images/van_ep_birch/2.png"),
            require("../assets/images/van_ep_birch/3.png"),
        ],
        inforSection: InforSectionVEB,
        detailSection: DetailSectionVEB,
    },
];

export const getProductName = (productName, language) => {
    return productName[language] || productName["vi"]; // Fallback to Vietnamese if language not found
};

export default products;
