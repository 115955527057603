import { useTranslation } from "react-i18next";
import styled, { keyframes } from "styled-components";

const StatusProduct = () => {
    const { i18n } = useTranslation();
    return (
        <StatusWrapper>
            <StatusDot />
            <StatusText>{i18n.language === "vi" ? "Còn hàng" : "In Stock"}</StatusText>
        </StatusWrapper>
    );
};

export default StatusProduct;

const pulse = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(34, 197, 94, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 6px rgba(34, 197, 94, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(34, 197, 94, 0);
  }
`;

const StatusWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 6px 12px;
    background-color: rgba(34, 197, 94, 0.1);
    border-radius: 16px;
    // margin: 8px 0;
`;

const StatusDot = styled.div`
    width: 8px;
    height: 8px;
    background-color: rgb(34, 197, 94);
    border-radius: 50%;
    animation: ${pulse} 2s infinite;
`;

const StatusText = styled.span`
    color: rgb(34, 197, 94);
    font-weight: 600;
    font-size: 0.875rem;
    // text-transform: uppercase;
    letter-spacing: 0.025em;
`;
