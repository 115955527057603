import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import styled, { css, keyframes } from "styled-components";
import productData, { getProductName } from "../../data/productList";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const shimmer = keyframes`
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
`;

const ProductContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    max-width: 56rem;
    padding: 0 1rem;

    @media (min-width: 768px) {
        padding: 0 2rem;
    }
`;

const ProductGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    width: 100%;

    @media (min-width: 640px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
    }

    @media (min-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
    }
`;

const ProductTitle = styled.div`
    width: 100%;
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    transform: ${({ inView }) => (inView ? "translateY(0)" : "translateY(2.5rem)")};
    opacity: ${({ inView }) => (inView ? 1 : 0)};
`;

const loadingAnimation = css`
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: ${shimmer} 1.5s infinite;
`;

const ProductCardWrapper = styled.a`
    position: relative;
    overflow: hidden;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    background: white;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    opacity: ${({ inView }) => (inView ? 1 : 0)};
    animation: ${({ inView }) => (inView ? fadeIn : "none")} 0.6s cubic-bezier(0.4, 0, 0.2, 1)
        forwards;
    transform-origin: center;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.1);
        z-index: 1;
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    &:hover {
        transform: translateY(-5px) scale(1.02);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);

        &::before {
            opacity: 1;
        }
    }
`;

const ImageContainer = styled.div`
    position: relative;
    padding-top: 75%;
    overflow: hidden;
    background: #f5f5f5;

    &::before {
        content: "";
        position: absolute;
        inset: 0;
        ${({ isLoading }) => isLoading && loadingAnimation}
    }
`;

const ProductImage = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    will-change: transform;

    ${ProductCardWrapper}:hover & {
        transform: scale(1.1) rotate(1deg);
    }
`;

const ProductInfo = styled.div`
    padding: 1rem;
    position: relative;
    z-index: 2;
    background: white;
    transition: transform 0.3s ease;

    @media (max-width: 768px) {
        padding: 0.5rem;
    }

    ${ProductCardWrapper}:hover & {
        transform: translateY(-5px);
    }
`;

const ProductName = styled.h3`
    font-size: 0.875rem;
    font-weight: 600;
    color: #1f2937;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: color 0.3s ease;

    @media (min-width: 768px) {
        font-size: 1rem;
    }

    ${ProductCardWrapper}:hover & {
        color: #f57923;
    }
`;

const MobileDetails = styled.p`
    display: block;
    color: #16a34a;
    font-size: 0.875rem;
    text-align: center;
    margin-top: 0.25rem;

    @media (min-width: 768px) {
        display: none;
        margin-top: 0.5rem;
    }
`;

const OverlayDetails = styled.div`
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    backdrop-filter: blur(4px);

    ${ProductCardWrapper}:hover & {
        opacity: 1;
    }

    @media (max-width: 768px) {
        display: none;
    }
`;

const OverlayText = styled.p`
    color: white;
    font-weight: 600;
    font-size: 1.125rem;
    transform: translateY(20px);
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    padding: 0.5rem 1rem;
    border: 2px solid white;
    border-radius: 4px;

    ${ProductCardWrapper}:hover & {
        transform: translateY(0);
    }
`;

const ProductCard = ({ product, t }) => {
    const { i18n } = useTranslation();
    const { ref, inView } = useInView({
        threshold: 0.1,
        triggerOnce: true,
        rootMargin: "50px",
    });

    const [imageLoaded, setImageLoaded] = useState(false);

    return (
        <ProductCardWrapper ref={ref} href={`/products/${product.id}`} inView={inView}>
            <ImageContainer isLoading={!imageLoaded}>
                <ProductImage
                    src={product.images[0]}
                    alt={getProductName(product.name, i18n.language)}
                    loading="lazy"
                    onLoad={() => setImageLoaded(true)}
                />
            </ImageContainer>

            <ProductInfo>
                <ProductName>{getProductName(product.name, i18n.language)}</ProductName>
                <MobileDetails>{t("view_details")}</MobileDetails>
            </ProductInfo>

            <OverlayDetails>
                <OverlayText>{t("view_details")}</OverlayText>
            </OverlayDetails>
        </ProductCardWrapper>
    );
};

const ProductsHome = () => {
    const { t } = useTranslation();
    const { ref, inView } = useInView({
        threshold: 0.1,
        triggerOnce: true,
        rootMargin: "50px",
    });

    return (
        <ProductContainer ref={ref}>
            <ProductTitle inView={inView}>
                <div className="session-title w-full">
                    <b />
                    <p className="hvr-underline-from-center text-lg md:text-xl lg:text-2xl">
                        {t("products")}
                    </p>
                    <b />
                </div>
            </ProductTitle>

            <ProductGrid>
                {productData.map((product) => (
                    <ProductCard key={product.id} product={product} t={t} />
                ))}
            </ProductGrid>
        </ProductContainer>
    );
};

export default ProductsHome;
