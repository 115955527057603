import React from "react";
import styled, { keyframes } from "styled-components";

const ManufacturerInfo = () => {
    return (
        <ManufacturerWrapper>
            <InfoContent>
                <Name>
                    PhuTho Wood
                    <VerifiedBadge>
                        <svg viewBox="0 0 24 24" fill="none" className="w-4 h-4">
                            <path
                                d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </VerifiedBadge>
                </Name>
            </InfoContent>
        </ManufacturerWrapper>
    );
};

export default ManufacturerInfo;

const shimmer = keyframes`
  0% {
    background-position: -40px 0;
  }
  100% {
    background-position: calc(100% + 40px) 0;
  }
`;

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const ManufacturerWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 12px 16px;
    background: linear-gradient(135deg, rgba(59, 130, 246, 0.05) 0%, rgba(59, 130, 246, 0.1) 100%);
    border-radius: 12px;
    margin: 10px 0;
    transition: all 0.3s ease;
    animation: ${fadeIn} 0.5s ease-out;

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba(59, 130, 246, 0.1);
    }
`;

const InfoContent = styled.div`
    display: flex;
    flex-direction: column;
`;

const Name = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 1rem;
    font-weight: 600;
    color: #1f2937;
    background: linear-gradient(90deg, #3b82f6 0%, #2563eb 50%, #3b82f6 100%);
    background-size: 200% auto;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: ${shimmer} 3s linear infinite;
`;

const VerifiedBadge = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    color: #3b82f6;
    cursor: pointer;

    &:hover > div {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
`;
