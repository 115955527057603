import { useTranslation } from "react-i18next";
import styled from "styled-components";

const DetailSection = () => {
    const { t, i18n } = useTranslation();
    return (
        <DetailWrapper>
            <DetailHeader>{t("product-details")}</DetailHeader>
            <DetailContent>
                <Section>
                    <SectionTitle>
                        {i18n.language === "vi"
                            ? "Giới thiệu ván ép bao bì"
                            : "What is Film-faced Plywood?"}
                    </SectionTitle>
                    <SectionContent>
                        <p className="pl-4 text-gray-600 leading-relaxed">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {i18n.language === "vi" ? (
                                <>
                                    <strong>Ván ép phủ phim</strong> là loại ván ép có một lớp phim
                                    phủ bảo vệ trên bề mặt, thường là phim phenolic hoặc melamine,
                                    giúp tăng cường độ bền và khả năng chịu nước.
                                </>
                            ) : (
                                <>
                                    <strong>Film-faced plywood</strong> is a kind of plywood with
                                    the face and back made from film with phenol or melamine glue,
                                    and the core is made from hardwood. They are strong, water
                                    resistant, suitable for many purposes like construction,
                                    furniture, and shuttering...
                                </>
                            )}
                        </p>
                        <ContentTitle className="text-lg pl-4">
                            {i18n.language === "vi" ? "Cấu tạo" : "Structure"}
                        </ContentTitle>
                        <p className="content-text">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {i18n.language === "vi" ? (
                                <>
                                    <strong>Ván ép phủ phim</strong> được cấu tạo từ nhiều lớp ván
                                    mỏng được liên kết với nhau bằng keo chống nước. Bề mặt của nó
                                    được bao phủ bằng một lớp phim phenolic hoặc melamine để cải
                                    thiện khả năng chống thấm nước, chống mài mòn và dễ dàng vệ
                                    sinh.
                                </>
                            ) : (
                                <>
                                    <strong>Film-faced plywood</strong> is made of many thin layers
                                    of plywood bonded with waterproof glue. Its surface is covered
                                    with a layer of phenolic or melamine film to improve water
                                    resistance, wear resistance, and easy cleaning.
                                </>
                            )}
                        </p>
                    </SectionContent>
                </Section>

                <Section>
                    <SectionTitle>{t("product-information")}</SectionTitle>
                    <TableWrapper>
                        <Table>
                            <tbody>
                                <Tr>
                                    <TdLabel>{t("measurement")}</TdLabel>
                                    <TdContent>1220x2440mm, 910x1820mm,….</TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("popular-thickness")}</TdLabel>
                                    <TdContent>
                                        <p>11.5mm - 7 {t("layer")}</p>
                                        <p>15mm - 9 {t("layer")}</p>
                                        <p>18mm - 11 {t("layer")}</p>
                                        <p>21mm - 13 {t("layer")}</p>
                                    </TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("tolerance")}</TdLabel>
                                    <TdContent>±0.3mm</TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("veneer-grade")}</TdLabel>
                                    <TdContent>
                                        {" "}
                                        {i18n.language === "vi"
                                            ? "Gỗ Bạch đàn, gỗ Keo (100% loại A)"
                                            : "Eucalyptus wood, Acacia wood (100% grade A)"}
                                    </TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("application")}</TdLabel>
                                    <TdContent>
                                        {i18n.language === "vi"
                                            ? "Cốp-pha xây dựng, đóng tàu, hộp cửa chớp, nội thất,..."
                                            : "Formwork, Construction, Building materials, Shuttering..."}
                                    </TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("glue")}</TdLabel>
                                    <TdContent>WBP 20% melanine</TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("formaldehyde-emission")}</TdLabel>
                                    <TdContent>E0/E1/E2</TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("face")}</TdLabel>
                                    <TdContent>
                                        {i18n.language === "vi"
                                            ? "Mặt phim phenolic dynea - 45 gsm"
                                            : "Phenolic dynea film surface - 45 gsm"}
                                    </TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("color")}</TdLabel>
                                    <TdContent>
                                        {i18n.language === "vi" ? "Nâu/Đen" : "Brown/Black"}
                                    </TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("species")}</TdLabel>
                                    <TdContent>
                                        {i18n.language === "vi"
                                            ? "Bạch đàn, Keo"
                                            : "Eucalyptus, Acacia"}
                                    </TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("technology-information")}</TdLabel>
                                    <TdContent>
                                        <p>
                                            {i18n.language === "vi"
                                                ? "1 lần ép nguội"
                                                : "1 cold pressing time"}
                                        </p>
                                        <p>
                                            {i18n.language === "vi"
                                                ? "2 lần ép nóng"
                                                : "2 hot pressing times"}
                                        </p>
                                        <p>
                                            {i18n.language === "vi"
                                                ? "2 lần chà"
                                                : "2 sanding times"}
                                        </p>
                                        <p>
                                            {i18n.language === "vi"
                                                ? "Máy ép nóng 6-8 pitton"
                                                : "6-8 pittons hot pressing machine"}
                                        </p>
                                    </TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("density")}</TdLabel>
                                    <TdContent>
                                        600 - 650 kgs/m<sup>3</sup>
                                    </TdContent>
                                </Tr>
                                <Tr>
                                    <TdLabel>{t("moisture")}</TdLabel>
                                    <TdContent>
                                        {i18n.language === "vi" ? "Dưới" : "Under"} 12%
                                    </TdContent>
                                </Tr>
                            </tbody>
                        </Table>
                    </TableWrapper>
                </Section>

                <Section>
                    <SectionTitle>
                        {i18n.language === "vi"
                            ? "Ứng dụng của ván ép phủ phim"
                            : "Applications of Film-coated Plywood"}
                    </SectionTitle>
                    <SectionContent>
                        <p className="content-text">
                            {i18n.language === "vi"
                                ? "Ván ép phủ phim được sử dụng trong nhiều lĩnh vực khác nhau, đặc biệt là trong ngành xây dựng và công nghiệp, nhờ vào độ bền, khả năng chịu lực tốt và chống ẩm. Dưới đây là một số ứng dụng chính của ván ép phủ phim:"
                                : "Due to its durability, high load-bearing capacity, and moisture resistance, film-faced plywood is used in many different fields, especially in the construction and industrial sectors. Here are some of the main applications of film-faced plywood:"}
                        </p>

                        <ContentTitle>
                            {i18n.language === "vi" ? "Cốp pha xây dựng" : "Construction Formwork"}
                        </ContentTitle>
                        <p className="content-text">
                            {i18n.language === "vi"
                                ? "Ván ép phủ phim thường được dùng làm cốp pha cho các công trình xây dựng, giúp định hình bê tông. Nhờ lớp phim trên bề mặt, ván có khả năng chống thấm nước và dễ dàng tháo ra sau khi bê tông đã khô."
                                : "Film-faced plywood is often used as formwork for construction projects, helping shape the concrete. Thanks to the film layer on the surface, the board is waterproof and can be easily removed after the concrete has dried."}
                        </p>

                        <ContentTitle>
                            {i18n.language === "vi"
                                ? "Sàn xe tải, xe container"
                                : "Truck and Container Floors"}
                        </ContentTitle>
                        <p className="content-text">
                            {i18n.language === "vi"
                                ? "Loại ván này được sử dụng làm sàn cho các phương tiện vận tải nhờ vào độ bền cao, khả năng chịu tải lớn và chống trầy xước."
                                : "This type of plywood is used as flooring for vehicles thanks to its high durability, large load-bearing capacity, and scratch resistance."}
                        </p>

                        <ContentTitle>
                            {i18n.language === "vi"
                                ? "Sàn nhà kho, nhà xưởng"
                                : "Warehouse and Factory Floors"}
                        </ContentTitle>
                        <p className="content-text">
                            {i18n.language === "vi"
                                ? "Trong các công trình công nghiệp, ván ép phủ phim cũng được dùng làm sàn vì độ cứng và khả năng chống nước tốt."
                                : "In industrial projects, film-faced plywood is also used as flooring because of its hardness and good water resistance."}
                        </p>

                        <ContentTitle>
                            {i18n.language === "vi" ? "Đóng đồ nội thất" : "Furniture Making"}
                        </ContentTitle>
                        <p className="content-text">
                            {i18n.language === "vi"
                                ? "Ván ép phủ phim còn có thể được sử dụng để sản xuất đồ nội thất như bàn, ghế, tủ, khi yêu cầu thẩm mỹ không quá cao và ưu tiên độ bền."
                                : "Film-faced plywood can also be used to produce furniture such as tables, chairs, and cabinets when aesthetic requirements are not too high and durability is a priority."}
                        </p>

                        <ContentTitle>
                            {i18n.language === "vi"
                                ? "Xây dựng cầu đường"
                                : "Bridge and Road Construction"}
                        </ContentTitle>
                        <p className="content-text">
                            {i18n.language === "vi"
                                ? "Được áp dụng trong các dự án xây dựng cầu đường để làm cốp pha cho các kết cấu bê tông, đảm bảo độ chính xác và bề mặt phẳng."
                                : "Applied in road and bridge construction projects to make formwork for concrete structures, ensuring accuracy and flat surfaces."}
                        </p>
                    </SectionContent>
                </Section>
            </DetailContent>
        </DetailWrapper>
    );
};

export default DetailSection;

const DetailWrapper = styled.div`
    position: relative;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    padding: 24px;
    margin: 32px 0;
`;

const DetailHeader = styled.div`
    position: absolute;
    top: -20px;
    left: 16px;
    background-color: white;
    padding: 0 12px;
    font-size: 1.5rem;
    font-weight: 600;
    // text-transform: uppercase;
    color: #4a5568;
`;

const DetailContent = styled.div`
    margin-top: 16px;
`;

const Section = styled.section`
    margin-bottom: 24px;
`;

const SectionTitle = styled.h3`
    color: #2f855a;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 16px;
`;
const TableWrapper = styled.div`
    // width: 100%;
    margin: 0 -16px;
    // overflow-x: auto;

    @media (min-width: 768px) {
        margin: 0 auto;
    }
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;

    @media (min-width: 768px) {
        min-width: 500px;
        max-width: 750px;
        margin-left: auto;
        margin-right: auto;
    }
`;

const Tr = styled.tr`
    &:hover {
        background-color: #f8f9fa;
    }
`;

const TdLabel = styled.td`
    font-weight: bold;
    width: 30%;
    min-width: 120px;
    word-wrap: break-word;
    white-space: normal;
    // padding: 8px;

    @media (min-width: 768px) {
        width: 25%;
        min-width: 150px;
    }
`;

const TdContent = styled.td`
    width: 65%;
    word-wrap: break-word;
    white-space: normal;
    padding: 8px;

    @media (min-width: 768px) {
        width: 75%;
    }
`;

const SectionContent = styled.div`
    .content-text {
        color: #4b5563; // equivalent to text-gray-600
        margin-left: 1rem; // equivalent to ml-4
        margin-bottom: 1rem; // equivalent to mb-4
        line-height: 1.625; // equivalent to leading-relaxed
    }

    .content-list {
        list-style-type: disc;
        list-style-position: outside;
        margin-left: 1rem;
        margin-bottom: 1rem;
        color: #4b5563;

        li {
            margin-bottom: 0.5rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
`;

const ContentTitle = styled.h4`
    font-weight: 600;
    color: #374151;
    margin-bottom: 5px;
    margin-left: 10px;
`;
