import React, { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { FaFacebook, FaMapMarkerAlt, FaPhone, FaWhatsapp } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { SiZalo } from "react-icons/si";
import styled from "styled-components";

const Footer = () => {
    const { t, i18n } = useTranslation();
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        preferredDate: "",
        message: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER}/api/confirm-order`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    ...formData,
                    language: i18n.language,
                }),
            });

            if (!response.ok) {
                throw new Error("Failed to submit");
            }

            setFormData({
                name: "",
                email: "",
                phone: "",
                preferredDate: "",
                message: "",
            });

            toast.success(
                i18n.language === "vi"
                    ? "Đặt lịch tư vấn thành công! Chúng tôi sẽ liên hệ lại với bạn sớm."
                    : "Order submitted successfully! We will contact you soon."
            );
        } catch (error) {
            console.error("Error submitting form:", error);
            toast.error(
                i18n.language === "vi"
                    ? "Có lỗi xảy ra. Vui lòng thử lại sau."
                    : "An error occurred. Please try again later."
            );
        }
    };

    return (
        <FooterContainer>
            <FooterContent>
                <CompanyInfo>
                    <CompanyName>{t("company-name")}</CompanyName>

                    <ContactItem>
                        <FaMapMarkerAlt />
                        <span>{t("address")}</span>
                    </ContactItem>

                    <ContactItem>
                        <FaPhone />
                        <Link href={`tel:${t("phone")}`}> {t("phone")}</Link>
                    </ContactItem>

                    <ContactItem>
                        <MdEmail />
                        <Link href="mailto:ctlamsanphutho@gmail.com">ctlamsanphutho@gmail.com</Link>
                    </ContactItem>

                    <ContactItem>
                        <FaFacebook />
                        <Link
                            href="https://www.facebook.com/profile.php?id=61568503735365"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Facebook Page
                        </Link>
                    </ContactItem>

                    <ContactItem>
                        <FaWhatsapp />
                        <span>WhatsApp: +84 396 246 882</span>
                    </ContactItem>

                    <ContactItem>
                        <SiZalo />
                        <span>Zalo: {t("phone")}</span>
                    </ContactItem>
                </CompanyInfo>

                <ConsultationForm onSubmit={handleSubmit}>
                    <FormTitle>
                        {i18n.language === "vi" ? "Liên hệ đặt hàng" : "Contact Order"}
                    </FormTitle>

                    <Input
                        type="text"
                        name="name"
                        placeholder={i18n.language === "vi" ? "Họ và tên" : "Full Name"}
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />

                    <Input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />

                    <Input
                        type="tel"
                        name="phone"
                        placeholder={i18n.language === "vi" ? "Số điện thoại" : "Phone Number"}
                        value={formData.phone}
                        onChange={handleChange}
                        required
                    />

                    <Input
                        type="datetime-local"
                        name="preferredDate"
                        value={formData.preferredDate}
                        onChange={handleChange}
                        required
                    />

                    <TextArea
                        name="message"
                        placeholder={
                            i18n.language === "vi" ? "Nội dung cần tư vấn..." : "Message..."
                        }
                        value={formData.message}
                        onChange={handleChange}
                        required
                    />

                    <SubmitButton type="submit">
                        {i18n.language === "vi" ? "Gửi yêu cầu" : "Send"}
                    </SubmitButton>
                </ConsultationForm>

                <Copyright>
                    <p>
                        © {new Date().getFullYear()} {t("company-name")}. All rights reserved.
                    </p>
                </Copyright>
            </FooterContent>
        </FooterContainer>
    );
};

export default Footer;
const FooterContainer = styled.footer`
    background-color: #16a34a;
    color: white;
    padding: 1.5rem 0;
    width: 100%;

    @media (max-width: 768px) {
        padding: 1rem 0;
    }
`;

const FooterContent = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 4rem;

    @media (max-width: 768px) {
        padding: 0;
        grid-template-columns: 1fr;
        gap: 0 1.5rem;
    }
`;

const CompanyInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    @media (max-width: 768px) {
        gap: 0.3rem;
    }
`;

const CompanyName = styled.h2`
    font-size: 1.8rem;
    font-weight: bold;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;

    &:after {
        content: "";
        display: block;
        width: 80px;
        height: 3px;
        background-color: white;
        margin-top: 0.5rem;
    }

    @media (max-width: 768px) {
        font-size: 1.5rem;

        &:after {
            width: 60px;
            height: 2px;
        }
    }
`;

const ContactItem = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
    transition: all 0.3s ease;
    padding: 0.5rem 0;
    word-break: break-word;

    &:hover {
        transform: translateX(5px);
        color: #e2e8f0;
    }

    svg {
        font-size: 1.2rem;
        min-width: 1.2rem;
    }

    @media (max-width: 768px) {
        font-size: 0.9rem;
        gap: 0.5rem;
        width: 95%;
        margin: 0 auto;

        svg {
            font-size: 1rem;
            min-width: 1rem;
        }
    }
`;

const Link = styled.a`
    color: white;
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
        color: #e2e8f0;
    }
`;

const ConsultationForm = styled.form`
    background: rgba(255, 255, 255, 0.1);
    padding: 2rem;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media (max-width: 768px) {
        width: 95%;
        margin: 0 auto;
        padding: 1.5rem;
        gap: 0.8rem;
    }
`;

const FormTitle = styled.h3`
    font-size: 1.5rem;
    margin-bottom: 1rem;
    font-weight: bold;

    @media (max-width: 768px) {
        font-size: 1.3rem;
        margin-bottom: 0.8rem;
    }
`;

const Input = styled.input`
    padding: 0.75rem;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.05);
    color: white;
    width: 100%;
    font-size: 1rem;

    &::placeholder {
        color: rgba(255, 255, 255, 0.7);
    }

    &:focus {
        outline: none;
        border-color: white;
    }

    @media (max-width: 768px) {
        font-size: 0.9rem;
        padding: 0.6rem;
    }
`;

const TextArea = styled.textarea`
    padding: 0.75rem;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.05);
    color: white;
    width: 100%;
    min-height: 100px;
    resize: vertical;
    font-size: 1rem;

    &::placeholder {
        color: rgba(255, 255, 255, 0.7);
    }

    &:focus {
        outline: none;
        border-color: white;
    }

    @media (max-width: 768px) {
        padding: 0.6rem;
        font-size: 0.9rem;
        min-height: 80px;
    }
`;

const SubmitButton = styled.button`
    padding: 0.75rem 1.5rem;
    background-color: white;
    color: #16a34a;
    border: none;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 1rem;

    &:hover {
        background-color: #e2e8f0;
        transform: translateY(-2px);
    }

    @media (max-width: 768px) {
        padding: 0.6rem 1.2rem;
        font-size: 0.9rem;
    }
`;

const Copyright = styled.div`
    margin-top: 2rem;
    padding-top: 2rem;
    text-align: center;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    grid-column: 1 / -1;

    @media (max-width: 768px) {
        margin-top: 1.5rem;
        padding-top: 1.5rem;
        font-size: 0.9rem;
    }
`;
