// import { useEffect } from "react";
// import { useTranslation } from "react-i18next";
// import { IoTime, IoTimer } from "react-icons/io5";
// import { MdFactory } from "react-icons/md";

// const AboutUsHome = () => {
//     const { t } = useTranslation();

//     const formatNumber = (number) => {
//         return Math.round(number)
//             .toString()
//             .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
//     };

//     useEffect(() => {
//         const animateCount = (element, endValue, duration) => {
//             let startValue = 0;
//             const increment = endValue / (duration / 10);
//             const interval = setInterval(() => {
//                 startValue += increment;
//                 if (startValue >= endValue) {
//                     element.innerText = formatNumber(endValue);
//                     clearInterval(interval);
//                 } else {
//                     element.innerText = formatNumber(startValue);
//                 }
//             }, 10);
//         };
//         const startCounting = () => {
//             animateCount(document.getElementById("experienceCount"), 10, 2000);
//             animateCount(document.getElementById("capacityCount"), 50000, 2000);
//             animateCount(document.getElementById("areaCount"), 20000, 2000);
//             window.removeEventListener("scroll", onScroll);
//         };

//         const onScroll = () => {
//             const statisticDiv = document.getElementById("statistic");
//             const rect = statisticDiv.getBoundingClientRect();
//             const distanceFromBottom = window.innerHeight - rect.bottom; // Khoảng cách từ đáy của phần tử đến đáy viewport

//             if (distanceFromBottom <= 300) {
//                 startCounting();
//             }
//         };

//         window.addEventListener("scroll", onScroll);
//         return () => window.removeEventListener("scroll", onScroll);
//     }, []);

//     return (
//         <div className="flex flex-col gap-3 w-full items-center md:w-5/6 lg:w-4/5 max-w-4xl">
//             <div className="session-title w-11/12">
//                 <b />
//                 <p className="hvr-underline-from-center">{t("about-us")}</p>
//                 <b />
//             </div>
//             <div className="w-11/12">
//                 <span className="font-bold text-lg pl-10">
//                     Công ty Sản xuất và Kinh doanh Lâm sản Phú Thọ
//                 </span>
//                 &nbsp;
//                 <span className=" text-[#111]">
//                     là đơn vị hàng đầu trong lĩnh vực chế biến và cung ứng sản phẩm từ gỗ tại Việt
//                     Nam. Với nhiều năm kinh nghiệm, công ty chuyên sản xuất và xuất khẩu các loại
//                     ván ép như: Ván ép bao bì, ván ép chà dán, ván ép phủ phim, ván ép LVL,... Chúng
//                     tôi cam kết mang đến cho khách hàng những sản phẩm bền đẹp, thân thiện với môi
//                     trường và đáp ứng các tiêu chuẩn quốc tế. Đội ngũ nhân viên tận tâm, cùng quy
//                     trình sản xuất hiện đại, Công ty Phú Thọ không ngừng đổi mới và phát triển,
//                     hướng tới mục tiêu trở thành thương hiệu tin cậy trong ngành lâm sản.
//                 </span>
//             </div>
//             <div id="statistic" className="flex w-full justify-around statistic-row">
//                 <div className="flex flex-col items-center">
//                     <div className="rounded-full bg-[#f57923] p-3">
//                         <IoTime color="white" size={28} />
//                     </div>
//                     <h4 className="font-semibold md:text-lg">
//                         <span id="experienceCount">0</span>+
//                     </h4>
//                     <h5 className="uppercase text-[#333] text-sm">năm kinh nghiệm</h5>
//                 </div>
//                 <div className="flex flex-col items-center">
//                     <div className="rounded-full bg-[#f57923] p-3">
//                         <IoTimer color="white" size={28} />
//                     </div>
//                     <h4 className="font-semibold md:text-lg">
//                         <span id="capacityCount">0</span> m<sup>3</sup>/năm
//                     </h4>
//                     <h5 className="uppercase text-[#333] text-sm">công suất nhà máy</h5>
//                 </div>
//                 <div className="flex flex-col items-center">
//                     <div className="rounded-full bg-[#f57923] p-3">
//                         <MdFactory color="white" size={28} />
//                     </div>
//                     <h4 className="font-semibold md:text-lg">
//                         <span id="areaCount">0</span> m<sup>2</sup>
//                     </h4>
//                     <h5 className="uppercase text-[#333] text-sm">diện tích nhà máy</h5>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default AboutUsHome;

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoTime, IoTimer } from "react-icons/io5";
import { MdFactory } from "react-icons/md";
import { useInView } from "react-intersection-observer";

const AboutUsHome = () => {
    const { t, i18n } = useTranslation();
    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: true,
    });

    const formatNumber = (number) => {
        return Math.round(number)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    const StatisticItem = ({ icon: Icon, endValue, unit, label }) => {
        const [count, setCount] = useState(0);

        useEffect(() => {
            if (inView) {
                const duration = 2000;
                const increment = endValue / (duration / 16);
                let currentValue = 0;

                const timer = setInterval(() => {
                    currentValue += increment;
                    if (currentValue >= endValue) {
                        setCount(endValue);
                        clearInterval(timer);
                    } else {
                        setCount(currentValue);
                    }
                }, 16);

                return () => clearInterval(timer);
            }
        }, [endValue]);

        return (
            <div className="flex flex-col items-center transform transition-transform duration-500 hover:scale-105">
                <div className="rounded-full bg-[#f57923] p-3 mb-2 shadow-lg">
                    <Icon color="white" size={28} />
                </div>
                <h4 className="font-semibold text-base md:text-lg lg:text-xl">
                    {formatNumber(Math.floor(count))}
                    {unit}
                </h4>
                <h5 className="uppercase text-[#333] text-xs md:text-sm mt-1">{label}</h5>
            </div>
        );
    };

    return (
        <div className="flex flex-col gap-3 w-full items-center px-4 md:w-5/6 lg:w-4/5 max-w-4xl">
            <div className="session-title w-full">
                <b />
                <p className="hvr-underline-from-center text-lg md:text-xl lg:text-2xl">
                    {t("about-us")}
                </p>
                <b />
            </div>

            <div className="w-full space-y-4">
                <h2 className="font-bold text-lg md:text-xl lg:text-2xl text-gray-800">
                    {t("company-name")}
                </h2>
                <p className="text-[#111] text-sm md:text-base lg:text-lg leading-relaxed">
                    {i18n.language === "vi"
                        ? `
                        là đơn vị hàng đầu trong lĩnh vực chế biến và cung ứng sản phẩm từ gỗ tại Việt Nam. 
                        Với nhiều năm kinh nghiệm, công ty chuyên sản xuất và xuất khẩu các loại ván ép như: 
                        Ván ép bao bì, ván ép chà dán, ván ép phủ phim, ván ép LVL,... Chúng tôi cam kết 
                        mang đến cho khách hàng những sản phẩm bền đẹp, thân thiện với môi trường và đáp ứng 
                        các tiêu chuẩn quốc tế. Đội ngũ nhân viên tận tâm, cùng quy trình sản xuất hiện đại, 
                        Công ty Phú Thọ không ngừng đổi mới và phát triển, hướng tới mục tiêu trở thành 
                        thương hiệu tin cậy trong ngành lâm sản.
                    `
                        : `
                        is a leading unit in the field of processing and supplying industrial wood products in Vietnam.
                        Owning many years of experience specializing in manufacturing and exporting various types of plywood:
                        Film-faced plywood, Commercial plywood, Packing plywood, LVL plywood, Core plywood, and Birch plywood…,
                        We are committed to bringing customers durable, environmentally friendly products that meet international standards.
                        With a dedicated staff and modern production processes, Phu Tho Wood Company constantly innovates and develops,
                        aiming to become a trusted brand in the forestry industry.
                    `}
                </p>
            </div>

            <div ref={ref} className="flex justify-around w-full mt-8 py-6">
                <StatisticItem icon={IoTime} endValue={10} unit="+" label={t("years-experience")} />
                <StatisticItem
                    icon={IoTimer}
                    endValue={50000}
                    unit={` m³/${i18n.language === "vi" ? "năm" : "year"}`}
                    label={t("factory-capacity")}
                />
                <StatisticItem
                    icon={MdFactory}
                    endValue={20000}
                    unit=" m²"
                    label={t("factory-area")}
                />
            </div>
        </div>
    );
};

export default AboutUsHome;
